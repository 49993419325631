.root {
  width: 100%;
  height: 55px;
  padding: 0 20px 0 15px;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #facdbc;
  border-radius: 3px;
  outline: 0;
  transition: all 200ms ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  &.invalid {
    background-color: #fff;
    color: red;

    &::placeholder {
      color: red;
    }
  }

  &:focus {
    border-radius: 3px;
    background-color: #fff;
    border-color: #ea5a41;
    color: #333;

    &::after {
      border-top-color: #ea5a41;
    }
  }

  option {
    background-color: #fff;
    color: #5f5f5f;
  }
}

.wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 50%;
    margin-top: -3px;
    height: 0;
    width: 0;
    border-top: 6px solid #facdbc;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    pointer-events: none;
  }
}

@media (max-width: 768px) {
  .root {
    height: 43px;
  }
}

