@mixin container {
  height: 100%;
}

@mixin common {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin spacing {
  padding: 15px;

  @media (max-width: 991px) {
    padding: 10px;
  }
}

/* from left to right */
.leftRight {
  @include container();

  & > div {
    height: 100%;
  }

  .enter {
    @include common();
    transform: translate(100%);

    &.enterActive {
      transform: translate(0%);
      transition: transform 300ms ease-in-out;
    }
  }

  .exit {
    @include common();
    transform: translate(0%);

    &.exitActive {
      transform: translate(-100%);
      transition: transform 300ms ease-in-out;
    }
  }

  &.fromSummary {
    .enter {
      @include spacing();
    }

    .exit {
      @include spacing();
    }
  }
}

/* from right to left */
.rightLeft {
  @include container();

  & > div {
    height: 100%;
  }

  .enter {
    @include common();
    transform: translate(-100%);

    &.enterActive {
      transform: translate(0%);
      transition: transform 300ms ease-in-out;
    }
  }

  .exit {
    @include common();
    transform: translate(0%);

    &.exitActive {
      transform: translate(100%);
      transition: transform 300ms ease-in-out;
    }
  }

  &.fromSummary {
    .enter {
      @include spacing();
    }

    .exit {
      @include spacing();
    }
  }
}

/* from top to bottom */
.topBottom {
  @include container();

  .enter {
    @include common();
    transform: translate(0%, 100%);

    &.enterActive {
      transform: translate(0%, 0%);
      transition: transform 300ms ease-in-out;
    }
  }

  .exit {
    @include common();
    transform: translate(0%, 0%);

    &.exitActive {
      transform: translate(0%, -100%);
      transition: transform 300ms ease-in-out;
    }
  }

  &.fromSummary {
    .enter {
      @include spacing();
    }

    .exit {
      @include spacing();
    }
  }
}

/* from bottom to top */
.bottomTop {
  @include container();

  .enter {
    @include common();
    transform: translate(0%, -100%);

    &.enterActive {
      transform: translate(0%, 0%);
      transition: transform 300ms ease-in-out;
    }
  }

  .exit {
    @include common();
    transform: translate(0%, 0%);

    &.exitActive {
      transform: translate(0%, 100%);
      transition: transform 300ms ease-in-out;
    }
  }

  &.fromSummary {
    .enter {
      @include spacing();
    }

    .exit {
      @include spacing();
    }
  }
}
