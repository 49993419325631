.root {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #fff;
  color: #ea5a41;
  font-weight: 400;
  text-align: center;
}
