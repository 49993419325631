.icon {
  padding: 25px;
  width: 90px;
  height: 90px;
  background-color: #facdbc;

  img {
    width: 100%;
  }
}

.title {
  margin-bottom: 20px !important;
}

.subTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding-bottom: 58px;
  //border-bottom: 1px solid black;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

