h2.maintenanceTitle {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif;
}

.logo {
  height: 70px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.image {
  max-width: 300px;
}

@media (max-width: 991px) {
  h2.maintenanceTitle {
    font-size: 40px;
    line-height: 50px;
  }

  .logo {
    top: 20px;
    left: 40px;
    height: 70px;
    width: auto;
  }
}

@media (max-width: 768px) {
  h2.maintenanceTitle {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 479px) {
  h2.maintenanceTitle {
    font-size: 20px;
    line-height: 26px;
  }

  .logo {
    top: 20px;
    right: 20px;
    left: initial;
    height: 60px;
    width: 60px;
  }

  .image {
    max-width: 160px;
  }
}