.showMoreWrapper {
  height: 80px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;

  &.shown {
    height: auto;
    margin-bottom: 0;
  }
}

.overlay {
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, transparent, #fff);
}

.button {
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  display: block;
  position: relative;
  top: -35px;
}