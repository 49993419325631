@import 'components/sketch/navigation/SketchCommonButton.scss';

.root {
  min-height: 100%;
  background-color: #333;
}

.header {
  padding: 14px;
  border-bottom: 1px solid #252525;
  display: flex;
  flex-direction: row-reverse;

  .close {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .item {
    @extend %button;

    .icon {
      @extend %icon;
    }
  }
}

.text {
  margin: 0 10px 10px;

  .textSmall, .textMedium, .textLarge {
    text-transform: none;
    padding: 9px 9px;
  }

  .textSmall {
    font-size: 12px;
  }

  .textMedium {
    font-size: 16px;
  }

  .textLarge {
    font-size: 20px;
  }
}

.deer {
  transform: scale(0.7); // 50/35
}

.roadCrossing {
  position: relative;
  top: 3px;
  width: 57px !important;
  height: 57px !important;
}

.shape {
  transform: scale(1.34); // 67/35
}

.participant {
  width: 57px !important;
  height: 92px !important;
  transform: scale(0.6);
}
