@import "../../sass/variables";

.root {
  font-size: 16px;
  line-height: 27px;
  max-width: 100%;

  ul {
    list-style-position: outside;
    padding-left: 30px;
  }

  li {
    padding: 6px 0;
    list-style-type: square;

    a {
      color: #00a8f6;
    }
  }

  a {
    color: #333;
  }
}

.missing {
  color: red;
}

h2.root {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif;
}

h5.root {
  margin-top: 20px;
  margin-bottom: 0;
}

.variant1 {
  font-size: 22px !important;
  color: #333;
  line-height: 29px !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

.variant2 {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.variant4,
.variant20 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

.variant3 {
  color: #333;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.variant4 {
  color: white;
}

.variant5 {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px !important;
  width: 100%;
  display: inline-block;
}

.variant6 {
  color: #fff;
}

.variant7 {
  font-size: 13px;
  line-height: 19px;
}

.variant8 {
  font-size: 14px;
  font-weight: 500;
}

.variant9 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #333;
}

.variant10 {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 500;
}

.variant11 {
  font-size: 23px;
  font-weight: 400;
  color: white;
  margin-bottom: 5px;
}

.variant12 {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  color: #333;

  p {
    margin: 0 0 10px;
  }
}

.variant13 {
  display: inline-block;
  margin-top: 10px;
  background-color: #333;
  color: white;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 20px;
  padding: 1px 10px;
}

.variant14 {
  color: #c4c4c4 !important;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

h2.variant15 {
  color: #fff;
  font-size: 34px;
  line-height: 40px;
}

.variant16 {
  font-size: 13px;
  font-weight: 500;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 3px;
  margin-top: 0;
  width: 100%;
}

.variant17 {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}

.variant18 {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
  display: block;
}

.variant19 {
  font-size: 21px;
  color: #c4c4c4;
}

.variant20 {
  color: #c4c4c4;
}

.variant21 {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  padding: 0 9px;
  color: #fff;
  border-radius: 15px;
}

.variant22 {
  color: #a1a1a1;
  font-size: 11px;
  text-transform: uppercase;
}

.variant23 {
  a {
    color: #c21b17;
  }

  &.wiz {
    a {
      color: $wiz-primary;
    }
  }
}

.variant24 {
  font-size: 21px;
  margin: 0;
}

.variant25 {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.variant26 {
  color: #333;
  text-transform: none;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 0 0 8px;
  line-height: 1.6;
  letter-spacing: initial;
}

.variant27 {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: initial;
}

.variant28 {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}

.variant29 {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  margin: 0 !important;
}

.variant30 {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0;
}

.variant31 {
  font-size: 21px;
  margin: 0;
}

.variant32 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #333;
  word-wrap: break-word;
  width: calc(100% - 100px);
}

.variant33 {
  display: block;
  padding: 25px 0 5px;
  font-size: 19px;
  font-weight: 700;
  color: #333;
}

.variant34 {
  font-size: 14px;
  color: #333;
}

.variant35 {
  font-size: 12px;
  color: #c21b17;
  margin: 0;
  line-height: 23px;
}

.variant36 {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  margin: 0 !important;
}

.checkboxInverted {
  color: #fff;
  font-size: 1rem; /* 16/16 */
}

@media (max-width: 991px) {
  h2.root {
    font-size: 40px;
    line-height: 50px;
  }
}

@media (max-width: 768px) {
  .root {
    font-size: 14px;
    line-height: 25px;
  }

  h2.root {
    font-size: 30px;
    line-height: 40px;
  }

  .variant3 {
    font-size: 11px;
  }

  .variant10 {
    font-size: 22px;
  }

  .variant11 {
    font-size: 23px;
  }

  .variant12 {
    font-size: 10px;
  }

  .variant17 {
    font-size: 18px;
  }

  .variant19,
  .variant24,
  .variant31 {
    font-size: 21px;
  }

  .variant26,
  .variant27,
  .variant28 {
    font-size: 13px;
  }

  .variant27 {
    font-size: 15px;
  }

  .variant29 {
    font-size: 24px;
  }

  .variant33 {
    font-size: 19px;
  }

  .variant34 {
    font-size: 14px;
  }

  .variant36 {
    font-size: 24px;
  }
}

@media (max-width: 479px) {
  .root {
    font-size: 12px;
    line-height: 23px;
  }

  h2.root {
    font-size: 20px;
    line-height: 26px;
  }

  .variant11 {
    font-size: 19px;
  }

  .variant19,
  .variant24 {
    font-size: 21px;
  }
}
