.root {
  width: 25px;
  height: 25px;
  display: none;
  visibility: hidden;

  &.checked {
    ~ .checkmark {
      background-color: #ea5a41;

      &::after {
        opacity: 1;
      }
    }
  }
}

// .variant1.root {
//   width: 24px;
//   height: 24px;

//     &.checked {
//     ~ .checkmark {
//       background-color: #fff;
//     }
//   }
// }

.checkmark {
  display: inline-block;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #ea5a41;
  border-radius: 3px;
  position: relative;
  top: 3px;
  transition: all 200ms ease;
  cursor: pointer;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 3px;
    width: 17px;
    height: 15px;
    background-image: url(images/checkmark-blue.svg);
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .1s;
  }
}

// .variant1.checkmark {
//   background-color: #00a8f6;

//   &::after {
//     background-image: url(images/checkmark-blue.svg);
//   }
// }

::-ms-clear {
    display: none;
}

@media (max-width: 768px) {
  .root.singleLine {
    height: 40px;
  }
}
