h2.introTitle {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif;
}

.logo {
  height: 80px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 2;
}

.generaliLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: 1;
}
@media (max-width: 991px) {
  h2.introTitle {
    font-size: 40px;
    line-height: 50px;
  }

  .logo {
    top: 20px;
    right: 40px;
    height: 60px;
    width: 60px;
  }

  .wiz {
    .logo {
      height: auto;
      width: 150px;
    }
  }

  .generaliLogo {
    width: 350px;
  }
}

@media (max-width: 768px) {
  h2.introTitle {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 479px) {
  h2.introTitle {
    font-size: 20px;
    line-height: 26px;
  }
}