.container {
  height: 100%;
  min-height: 500px;
  position: relative;
  margin-bottom: 40px;
}

.container.isMobile {
   margin-bottom: 0;

  .icon {
    display: none;
  }
}

.icon {
  position: absolute;
  left: 0px;
  bottom: -25px;
  right: 0px;
  display: block;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 8px;
  border-radius: 50%;
  background-color: #333;
}

@media (max-width: 991px) {
  .container {
    margin-bottom: 20px;
  }
}
