.root {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;

  .icon {
    width: 32px;
    height: 32px;
  }
}
