.tree {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  border: 2px solid #facdbc;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  min-height: 40px;
  height: 100%;
  text-align: left;
  padding: 3px 10px !important;
  transition: background-color .3s, box-shadow .3 ease, opacity .3s ease;

  &.selected {
    background-color: #facdbc;
  }

  &:hover {
    // box-shadow: 0 0 23px 0 rgba(0, 0, 0, .3);
    background-color: #facdbc;
    border-color: #facdbc;
  }
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 25px;
}

.icon {
  display: inline-block;
  margin-right: 10px;
}

.arrow {
  position: absolute;
  top: 7px;
  right: 0;
  transition: transform .3s;

  & > div {
    transition: color .3s;
  }

  &.selected {
    transform: rotate(180deg);
  }
}

.children {
  position: relative;

  &.collapsed {
    display: none;
  }
}

.list {
  position: relative;
  margin: 0 0 0 45px;

  .arrow {
    margin-right: 25px;
  }
}

.childLabel {
  display: flex;
  align-items: center;
}

.option {
  margin: 0 10px 0 45px;
}
