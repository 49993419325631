.root {
  color: #ea5a41;
  font-family: Icomoon, sans-serif;
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  text-align: left;
}

.variant1 {
  font-size: 15px;
  height: 10px;
  line-height: 12px;
}
