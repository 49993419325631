@import "sass/_variables.scss";

.root {
  display: flex;
  width: 100%;
  padding-bottom: 0;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 5px 0 10px;
}

.icon {
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 10px 5px 10px 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.list {
  list-style: none;
  text-transform: none;
  margin-top: 0;
  padding: 15px 10px 15px;
  margin-bottom: 0;
  flex: 1;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.line {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  line-height: 16px;

  .column {
    padding-right: 10px;
    font-weight: 400;
  }
}

.lineValue {
  font-weight: 700;
}

.variant2,
.variant3 {
  border-top: 0;
  border-bottom: 0;
  border-radius: 7px;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  transition: background-color 200ms ease;

  .icon {
    padding: 20px 15px 15px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid white;
    background-color: transparent;
    border-width: 0;
  }

  .list {
    background-color: transparent;
    color: #333;

    & > li:nth-child(2) {
      margin-top: 10px;
    }
  }

  &:hover {
    background-color: #facdbc;
  }
}

.variant3 {
  background-color: #facdbc;
}

.variant4 {
  margin-top: 20px;
  padding: 15px 20px 18px;
  border-radius: 3px;
  background-color: #facdbc;
  border: none !important;
}

@media (max-width: 479px) {
  .line {
    .column {
      width: 100%;
      padding-bottom: 4px;
      float: none;
    }
  }
}