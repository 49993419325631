.root {
  display: flex;
  flex: 1 1 47%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px 0 8px;
  max-width: 47%;
  border-style: solid;
  border-width: 1px;
  border-color: #ea5a41;
  background-color: #facdbc;
  transition: background-color .3s;

  &:hover {
    background-color: #ea5a41;
  }
  

  .icon {
    width: 33px;
    height: 33px;
    margin-right: 10px;
  }

  .delete {
    cursor: pointer;
    width: 18px;
    margin: 5px;
  }
}

@media (max-width: 479px) {
  .root {
    flex: 1 1 100%;
    max-width: 100%;
  }
}