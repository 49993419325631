.root {
    // min-height: 350px;

    & > svg {
        max-width: 350px;
    }

    &.car {
        overflow: hidden;

        & > svg {
            min-height: 350px;
        }
    }

    &.semiTruck {
        overflow: hidden;

        & > svg {
            min-height: 350px;
        }
    }

    &.motorcycle {
    }

    &.bike {
    }
}