%container {
  width: 80px;
  height: 60px;
}

%button {
  width: 80px;
  height: 60px;
}

%icon {
  width: 37px;
  height: 37px;
}
