@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Oswald:700&subset=latin-ext');

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  min-height: 100%;
  overflow-x: hidden;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch !important;
}

body > svg {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: 'As vozila';
  src: url('./fonts/as-vozila.woff') format('woff'), url('./fonts/as-vozila.woff2') format('woff2'), url('./fonts/as-vozila.ttf') format('truetype'), url('./fonts/as-vozila.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'As nesreca';
  src: url('./fonts/as-nesreca.woff') format('woff'), url('./fonts/as-nesreca.woff2') format('woff2'), url('./fonts/as-nesreca.ttf') format('truetype'), url('./fonts/as-nesreca.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AS-ICONS-3';
  src: url('./fonts/AS-ICONS-3.woff') format('woff'), url('./fonts/AS-ICONS-3.woff2') format('woff2'), url('./fonts/AS-ICONS-3.ttf') format('truetype'), url('./fonts/AS-ICONS-3.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Asdom';
  src: url('./fonts/asdom.woff') format('woff'), url('./fonts/asdom.woff2') format('woff2'), url('./fonts/asdom.ttf') format('truetype'), url('./fonts/asdom.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Icomoon';
  src: url('./fonts/icomoon.woff') format('woff'), url('./fonts/icomoon.woff2') format('woff2'), url('./fonts/icomoon.ttf') format('truetype'), url('./fonts/icomoon.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'repairService';
  src: url('./fonts/repair-service.woff') format('woff'), url('./fonts/repair-service.woff2') format('woff2'), url('./fonts/repair-service.ttf') format('truetype'), url('./fonts/repair-service.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DOM-oseba';
  src: url('./fonts/DOM-oseba.woff') format('woff'), url('./fonts/DOM-oseba.woff2') format('woff2'), url('./fonts/DOM-oseba.ttf') format('truetype'), url('./fonts/DOM-oseba.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Generali-toca-poplava-steklo';
  src: url('./fonts/Generali-toca-poplava-steklo.woff') format('woff'), url('./fonts/Generali-toca-poplava-steklo.ttf') format('truetype'), url('./fonts/Generali-toca-poplava-steklo.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}