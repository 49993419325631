.root {
  border-radius: 5px;
  color: #fff;
  line-height: 47px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  background-color: #999;

  .iconContainer {
    padding-bottom: 0;
    font-weight: 400;
  }

  .icon {
    color: #fff;
  }

  &.variant1 {
    width: 59px;
    height: 92px;
    font-size: 35px;

    .iconContainer {
      padding-top: 11px;
    }

    .iconContainer, .icon {
      line-height: 35px;
      font-size: 35px;
    }

    .name {
      padding-top: 5px;
      font-size: 28px;
      line-height: 31px;
    }
  }

  &.variant2 {
    width: 35px;
    height: 54px;
    font-size: 20px;

    .iconContainer {
      padding-top: 8px;
    }

    .iconContainer, .icon {
      line-height: 20px;
      font-size: 20px;
    }

    .name {
      padding-top: 3px;
      font-size: 20px;
      line-height: 23px;
    }
  }
}
