.root {
  width: 100%;
  height: 55px;
  padding: 0 20px 0 15px;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  background-color: transparent;
  border: 1px solid #facdbc;
  border-radius: 5px;
  outline: 0;
  transition: all 200ms ease;

  &.singleLine {
    height: 55px;
  }

  &.multiLine {
    padding-top: 10px;
    height: 150px;
    min-width: 170px;
    overflow: auto; 
  }

  &.variant1 {
    height: 112px;
  }

  &.variant2 {
    background-color: #333 !important;
    color: white;
    -webkit-box-shadow: 0 0 0 1000px #333 inset !important;

    
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important;
    }

    &:focus {
      // background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      color: #333 !important;
      -webkit-text-fill-color: #333 !important;
    }

    &.invalid {
      // background-color: rgba(255, 0, 0, 0.2);
      color: red;
    }
  }

  &.variant3 {
    height: 43px;
    text-align: center;
  }

  &.variant4 {
    color: #fff !important;
    background-color: #4c4c4c;
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
    padding: 12px;
    margin: 0 1px;
    width: calc(100% - 2px);
    border-radius: 0 !important;
    border: 6px solid #817e7e;

    &.multiLine {
      height: 110px;
    }

    &:focus {
      border-color: #ea5a41;
      background-color: #4c4c4c;
      color: #fff !important;
      -webkit-text-fill-color: #fff !important;
      box-shadow: none;
    }
  }

  &.variant5 {
    &.multiLine {
      font-size: 13px;
      line-height: 22px;
      height: 80px;
    }
  }

  &.variant6 {
    background-color: #fff !important;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #333 !important;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3) !important;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important;
    }

    &:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      color: #333 !important;
      -webkit-text-fill-color: #333 !important;
    }

    &::placeholder {
      color: #aaa !important;
      -webkit-text-fill-color: #aaa !important;
    }
  }


  &.variant7 {
    background-color: #fff !important;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;


    &:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      color: #333 !important;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important;
    }
  }

  &.variant8 {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #ccc;
    border-radius: 5px;


    &:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      color: #333 !important;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }

    &::placeholder {
      color: #333 !important;
      -webkit-text-fill-color: #333 !important;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: #fff !important;
    }
  }

  &.invalid {
    background-color: #fff;
    color: red;

    &::placeholder {
      color: red;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      -webkit-text-fill-color: red !important;
    }
  }

  &.disabled {
    opacity: 0.39;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    -webkit-text-fill-color: #333 !important;
  }

  &::-ms-reveal {
    background-color: #fff;
  }

  &:focus {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border-color: #ea5a41;

    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    -webkit-text-fill-color: #333 !important;
  }

  &::placeholder {
    color: #333;
  }
}

:global .react-datepicker {
  &-wrapper {
    width: 100%;
  }
  
  &__input-container {
    width: 100%;
  }
}

::-ms-clear {
    display: none;
}

@media (max-width: 991px) {
  .root.variant8 {
    height: 40px;
  }
}

@media (max-width: 768px) {
  .root {
    height: 43px;

    &.multiLine {
      padding-top: 10px;
      height: 150px;
      min-width: 170px;
      overflow: auto; 
    }
  }
}
