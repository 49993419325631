.input {
  display: none;
}

.icon {
  display: flex;

  .label {
    flex: 1;
  }

  .progress {
  }
}