.root {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  top: 20px; right: 20px; bottom: 20px; left: 20px;
  border-radius: 5px;

  background-color: #333;
  overflow: auto;
  -ms-flex-align: center;

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .inner {
    width: 100vw;
    max-width: 550px;
    min-width: 200px;
    padding: 32px;
    margin: auto;
    -ms-flex-align: center;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 1001;
  }

  &.variant1 {
    display: block;
    border-radius: 0;

    .container {
      width: 100%;
      height: initial;
    }

    .inner {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;

      & > div {
        width: 100%;
      }
    }
  }

  &.map {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;

    .container {
      overflow: hidden;
    }

    .closeBackground {
      position: absolute;
      top: 0;
      width: 100%;
      height: 75px;
      background-color: #ea5a41;
      z-index: 1000;
    }

    .inner {
      padding: 0;
      height: 100%;
      max-width: 100%;
      height: 100vh;
    }
  }

  &.fromSummary {
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: #787878 !important;
    position: relative;
    padding: 15px;
    min-height: 100vh;
    border-radius: 0;

    & > .container > .inner {
      padding: 0;
      max-width: 100%;
      position: relative;
      min-height: calc(100vh - 30px);
    }

    .variant1 {
      padding: 13px;
      background-color: #333;
    }

    @media (max-width: 991px) {
      padding: 10px;

      & > .container > .inner {
        min-height: calc(100vh - 20px);
      }
    }
  }

  &.date {
    background-color: #fff;

    .close {
      background-color: #333;
    }
  }

  &.variant1 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);

    .container {
      position: relative;
      background-color: #fff;
      border-radius: 5px;
    }

    .inner {
      padding-top: 90px;
      padding-bottom: 90px;
      max-width: 500px;
      min-height: calc(100vh - 40px);
    }
  }

  &.popUp {
    background-color: rgba(0, 0, 0, 0.53);
    top: 0; right: 0; bottom: 0; left: 0;

    .inner {
      background-color: #fff;
      padding: 20px 15px;
      max-width: 400px;
    }
  }
}

@media (max-width: 991px) {
  .root {
    &.map {
      .closeBackground {
        height: 60px;
      }

      .inner {
        // height: calc(100vh - 56px);
      }
    }

    .inner {
      padding: 70px 32px;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .root.variant1 {
    .inner {
      padding: 70px 20px;
    }
  }
}

@media (max-width: 568px) {
  .root {
    top: 0; right: 0; bottom: 0; left: 0;
  }

  .root.variant1 {
    padding: 0;

    .container {
      border-radius: 0;
      height: 100%;
    }
  }
}
