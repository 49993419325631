.root {
  display: flex;
  width: 100%;
  max-width: 500px;

  &.fromSummary {
    visibility: hidden;

    &.hide {
      display: none;
    }
  }
}


.wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  padding-right: 150px;
  background-color: #c11d0d;

  &.fromSummary {
    margin: 0 5vw;
    background-color: #fff;
    padding-top: 30px;
    width: initial;

    .back {
      border-radius: 50%;
      background-color: #ea5a41;
    }
  }
}

.count {
  display: inline-block;
  color: #333;
  font-size: 30px;
  line-height: 46px;
  font-weight: 200;
  float: left;
}

.back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border: 0;
  border-radius: 0;
  background-color: #ea5a41;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition: all 200ms ease;

  img {
    width: 31px;
    height: 32px;
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: #c21b17;
    }
  }

  &:disabled {
    // opacity: 0.39;
    // cursor: default;

    // &:hover {
    //   background-color: #f1f1f1;
    // }
  }
}

.close {
  background-color: red;

  img {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: red;
    }
  }
}

.illustration {
  position: absolute;
  left: auto;
  top: 20px;
  right: 0%;
  bottom: auto;
  width: 115px;
}

@media (max-width: 479px) {
  .wrapper {
    padding-right: 0;
  }

  .illustration {
    width: 101px;
  }
}
