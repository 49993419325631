.root {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.inner {
    max-width: 550px;
    padding: 32px;

    @media (max-width: 991px) {
      padding: 20px;
    }
}
