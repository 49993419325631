@import "sass/_variables.scss";

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

%part {
  flex: 1;
  -ms-flex-align: center;
}

.left {
  @extend %part;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 155px;
  padding-bottom: 120px;
  background-color: white;
  border-right: 1px solid #c4c4c4;
  border-bottom: 6px solid #a71c34;

  height: 100vh;
  overflow: auto;

  .inner {
    margin: auto 5vw;
    width: 40vw;
  }
}

.right {
  @extend %part;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100vh;
  overflow: auto;

  .inner {
    display: flex;
    width: 100%; // IE fix
  }

  &.spacing .inner {
    -ms-flex-align: center;
    min-height: 50px;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
  }

  &.spacing .ieInner {
    min-height: auto;
  }

  &.map {
    display: block;
  }

  &.map .inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.isMobile.map {
    overflow: initial;
    
    .inner {
      justify-content: center;
      overflow-x: initial;
    }
  }

  &.centerPosition .inner > div {
    width: 100%;
  }

  &.maintenance {
    background-color: #c21b17;

    .inner {
      text-align: center;
    }
  }
}

.fromSummary {
  .left, .right {
    height: calc(100vh - 30px);
    border-bottom: 0;
  }
}

.spacing {
  padding-top: 5vh;
  padding-bottom: 5vh;
  overflow-x: hidden;
}

.reverseFlex {
  .left {
    border-bottom: none;
  }
}

.spacerRight {
  flex: 1 1 190px;
  max-width: 180px;
  width: 100%;
}

.spacerLeft {
  display: none;
}

.buttonContainer {
  position: absolute;
  top: 5vh; //margin-top value
  right: 25px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }
}

@media (max-height: 579px) {
  .centerPosition .inner {
    flex-direction: unset !important;
  }
}

@media (max-width: 991px) {
  .root {
    display: block;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    &.fromSummary {
      min-height: calc(100vh - 20px);
      display: flex;
    }
  }

  .left {
    display: block;
    padding-top: 130px;
    padding-bottom: 60px;
    height: initial;
    overflow: initial;
    min-height: 250px;
    border-bottom: 1px solid #facdbc;
    flex: 0;
    
    .inner {
      margin: 0 5vw;
      width: auto;
      justify-content: flex-start;
    }
  }

  .right {
    display: block;
    height: initial;
    overflow: initial;
    
    &.spacing .inner {
      padding: 0 5vw;
      margin: 0;
      max-width: none;
      width: 100%;
    }

    &.map{
      height: initial;

      .inner {
        overflow-x: initial;
      }
    }

    &.maintenance {
      height: auto !important;
      flex: 0 1 !important;
      padding: 40px 0 !important;
      min-height: 250px !important;

      .inner {
        text-align: right;
      }
    }
  }

  .fromSummary {
    .left, .right {
      height: auto;
    }
  }

  .spacing {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .backgroundImg {
    height: 200px;
    position: relative;
  }

  .reverseFlex {
    display: flex;
    flex-direction: column-reverse;
    background-color: #fff;
    
    .left {
      flex: 1;
      padding-top: 60px;
    }
    
    .right {
      flex: 0;
      min-height: 200px;
      height: 50vh;
      flex: auto;
    }
  }

  .spacerLeft, .spacerRight {
    display: block;
    flex: 1 0 auto;
    max-width: none;
    width: auto;
  }

  .buttonContainer {
    flex-direction: row-reverse;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    width: 100%;
    border-top: 1px solid #c4c4c4;
    background-color: #fff;

    > * {
      margin: 5px;
    }
  }
}

@media (max-width: 768px) {
  .left {
    padding-bottom: 30px;
  }

  .spacing {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .left .inner {
    margin: 0 45px;
  }

  .right {
    &.spacing .inner {
      margin: 0;
      padding: 0 45px;

      &>div {
        margin-top: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        margin-left: 0 !important;
      }
    }
  }

  .backgroundImg {
    // height: 240px;
  }
}

@media (max-width: 479px) {
  .left .inner {
    margin: 0 20px;
  }

  .right { 
    &.spacing .inner {
      margin: 0;
      padding: 0 20px;
    }

    &.map .inner {
      margin: 0;
    }

    &.maintenance {
      min-height: 180px !important;

      .inner {
        text-align: left;
      }
    }
  }

  .reverseFlex {
    .left {
      padding-top: 10px;
    }

    .right {
      height: 300px;
    }
  }
}
