.otherInput {
  max-width: 400px;
  width: 100%;
}

// half width IE fix
.halfWidthColumn {
  width: 100% !important;
}

@media (min-width: 768px) {
  .halfWidthColumn {
    max-width: 50%;
    min-width: 50%;
  }
}

@media (min-width: 992px) {
  .halfWidthColumn {
    max-width: none;
  }
}

@media (min-width: 1200px) {
  .halfWidthColumn {
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  .root.singleLine {
    width: auto;
  }
}

@media (min-width:992px) and (max-width:1474px) {
  .ieLgScreen {
    padding-bottom: 450px;
  }
}