.root {
  margin-top: 10px;
  margin-bottom: 20px;

  &.light {
    color: #fff;
  }

  &.dark {
    color: #00a8f6;
  }
}
