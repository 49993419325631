@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Oswald:700&subset=latin-ext);
.SketchCanvasItem_isDragging__31cyt {
  display: none; }

.SketchCanvas_root__3JbjF {
  -webkit-transition: fill 0.1s ease-in-out;
  transition: fill 0.1s ease-in-out; }

.Button_button__1RtXl {
  border-radius: 3px;
  background-color: #ea5a41;
  padding: 11px 48px;
  border: 2px solid #ea5a41;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }
  @media (min-width: 768px) {
    .Button_button__1RtXl:hover {
      background-color: #c21b17;
      border-color: #c21b17;
      color: #fff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3); } }
  .Button_button__1RtXl.Button_selected__3k-M7 {
    background-color: #facdbc;
    color: #c21b17; }
    .Button_button__1RtXl.Button_selected__3k-M7 span,
    .Button_button__1RtXl.Button_selected__3k-M7 p {
      color: #c21b17 !important; }
  .Button_button__1RtXl.Button_disabled__dDKVs {
    opacity: 0.39;
    cursor: default; }
    .Button_button__1RtXl.Button_disabled__dDKVs:hover {
      background-color: #ea5a41;
      border-color: #ea5a41;
      box-shadow: none; }

.Button_label__2PDy3 {
  width: 100%; }
  .Button_label__2PDy3 span {
    display: inline-block; }

.Button_iconLabelDescription__38Ac_ {
  display: block !important;
  margin-top: 0;
  padding: 10px 15px;
  height: 100%; }
  .Button_iconLabelDescription__38Ac_ .Button_icon__lnRSo > div {
    margin-bottom: 10px; }
  .Button_iconLabelDescription__38Ac_ .Button_label__2PDy3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px; }
  .Button_iconLabelDescription__38Ac_ .Button_description__2Xkwk {
    font-size: 11px;
    text-transform: none;
    text-transform: initial; }

.Button_inverseButton__1y6Zg {
  background-color: #ffffff;
  color: #00a8f6; }

.Button_variant1__c5JQa {
  background-color: #ffffff;
  color: #c21b17;
  border-width: 1px;
  padding: 18px 49px;
  margin-top: 40px;
  border-radius: 0;
  font-size: 12px;
  line-height: 27px; }
  .Button_variant1__c5JQa.Button_wiz__3MbWI {
    color: #27bdbe;
    border-color: #27bdbe; }
    .Button_variant1__c5JQa.Button_wiz__3MbWI:hover {
      background-color: #1ba6a7;
      color: #fff;
      border-color: #1ba6a7; }
  @media (min-width: 768px) {
    .Button_variant1__c5JQa:hover {
      color: #fff; } }

.Button_variant2__3lnKw {
  padding: 0 !important; }
  .Button_variant2__3lnKw .Button_label__2PDy3 {
    -webkit-flex: 1 1;
            flex: 1 1; }

.Button_variant3__1vE8C {
  width: 100%;
  height: 80px;
  background-color: #c21b17 !important; }
  .Button_variant3__1vE8C.Button_selected__3k-M7 {
    box-shadow: 0 0 !important;
    background-color: #ea5a41 !important;
    color: #fff !important; }
  @media (min-width: 768px) {
    .Button_variant3__1vE8C:hover {
      box-shadow: 0 0 !important;
      background-color: #ea5a41 !important;
      color: #fff !important; } }

.Button_variant4__3QRtY {
  width: 100%;
  height: 80px;
  background-color: transparent;
  border: 0;
  border-right: 1px solid #252525;
  border-bottom: 1px solid #252525;
  background-color: transparent !important; }
  .Button_variant4__3QRtY.Button_selected__3k-M7 {
    box-shadow: 0 0 !important;
    background-color: #252525 !important;
    border-color: #252525 !important; }
  @media (min-width: 768px) {
    .Button_variant4__3QRtY:hover {
      box-shadow: 0 0 !important;
      background-color: #252525 !important;
      border-color: #252525 !important; } }

.Button_variant5__1vV8l {
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: #a71c34;
  padding: 19px 0 19px 0 !important;
  text-align: center; }
  .Button_variant5__1vV8l .Button_icon__lnRSo {
    height: 50px;
    margin: 0 !important; }
  @media (min-width: 768px) {
    .Button_variant5__1vV8l:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important; } }

.Button_variant6__3vpLG {
  width: 50px !important;
  height: 50px !important;
  border: 0;
  background-color: #c21b17; }
  .Button_variant6__3vpLG .Button_icon__lnRSo {
    padding: 6px; }
  @media (min-width: 768px) {
    .Button_variant6__3vpLG:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important; }
    .Button_variant6__3vpLG.Button_disabled__dDKVs:hover {
      background-color: #ea5a41; } }

.Button_variant7__1MUDZ {
  width: 60px !important;
  height: 60px !important;
  border: 0;
  background-color: #c21b17; }
  .Button_variant7__1MUDZ .Button_icon__lnRSo {
    padding: 12px 6px 6px; }
  @media (min-width: 768px) {
    .Button_variant7__1MUDZ:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important; } }

.Button_variant8__3jWG3 {
  padding-left: 55px !important;
  position: relative;
  background-color: #fff;
  border: 2px solid #facdbc;
  border-radius: 3px;
  color: #333;
  text-align: left; }
  .Button_variant8__3jWG3 .Button_icon__lnRSo {
    position: absolute;
    left: 8px;
    top: 20px; }
    .Button_variant8__3jWG3 .Button_icon__lnRSo img {
      width: 35px; }
  .Button_variant8__3jWG3 .Button_description__2Xkwk {
    font-size: 13px !important;
    font-weight: 500;
    letter-spacing: normal;
    letter-spacing: initial; }
  @media (min-width: 768px) {
    .Button_variant8__3jWG3:hover {
      background-color: #facdbc;
      border-color: #facdbc;
      color: #333; } }

.Button_variant9__3qvRE {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #00a8f6;
  border-color: #00a8f6;
  padding: 9px 27px;
  font-size: 12px;
  line-height: 17px;
  margin-top: 20px;
  font-weight: 600; }
  @media (min-width: 768px) {
    .Button_variant9__3qvRE:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3); } }

.Button_variant10__1YKvT {
  font-size: 10px;
  line-height: 11px;
  padding: 8px 15px; }

.Button_variant11__3Pmey {
  min-height: 46px !important;
  height: 46px !important; }

.Button_variant12__3cs_V {
  background-color: #333; }
  @media (min-width: 768px) {
    .Button_variant12__3cs_V:hover {
      box-shadow: 0 0 23px 0 rgba(255, 255, 255, 0.3); } }

.Button_variant13__ZHMx9 {
  display: inline-block !important;
  min-height: 20px !important;
  height: auto !important;
  background-color: #ea5a41 !important;
  padding: 11px 48px !important;
  color: #fff !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  border: 2px solid #ea5a41 !important; }
  @media (min-width: 768px) {
    .Button_variant13__ZHMx9:hover {
      background-color: #c21b17 !important;
      border-color: #c21b17 !important;
      color: #fff !important; } }
  .Button_variant13__ZHMx9.Button_selected__3k-M7 {
    color: #fff !important; }
    .Button_variant13__ZHMx9.Button_selected__3k-M7 span {
      color: #fff !important; }

.Button_variant14__2RlHK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  color: #333;
  border: 2px solid #facdbc;
  border-radius: 3px;
  margin-top: 0;
  min-height: 60px;
  height: 100%; }
  @media (min-width: 768px) {
    .Button_variant14__2RlHK:hover {
      background-color: #facdbc;
      border-color: #facdbc;
      color: #333; } }
  .Button_variant14__2RlHK .Button_label__2PDy3 {
    -webkit-flex: 1 1;
            flex: 1 1; }

.Button_variant15__1KA1k {
  border: 0 !important;
  border-radius: 0 !important;
  min-height: 0 !important;
  margin-left: 1px; }
  .Button_variant15__1KA1k.Button_selected__3k-M7 {
    background-color: #3fbdf8;
    color: #fff; }

.Button_variant16__3961U {
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 1px;
  padding: 8px 30px 8px 10px !important;
  width: 100%;
  display: -webkit-flex;
  display: flex; }
  .Button_variant16__3961U .Button_icon__lnRSo {
    width: 10px;
    position: relative; }
    .Button_variant16__3961U .Button_icon__lnRSo img {
      position: absolute;
      top: -3px;
      height: 16px;
      width: 16px; }

.Button_variant17__1S9Qn {
  margin-top: -6px;
  border: 1px solid #facdbc; }
  @media (min-width: 768px) {
    .Button_variant17__1S9Qn:hover {
      border-color: #facdbc; } }
  .Button_variant17__1S9Qn.Button_onlyIcon__3A0lv {
    background-color: transparent;
    height: 55px;
    width: 55px; }

.Button_variant18__WbpqB {
  font-size: 12px;
  font-weight: 500;
  line-height: 11px;
  padding: 8px 15px;
  letter-spacing: 0;
  text-transform: none; }

.Button_variant19__24vLE {
  position: relative;
  border: 2px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3); }
  .Button_variant19__24vLE .Button_label__2PDy3 {
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 5px; }
  .Button_variant19__24vLE .Button_description__2Xkwk {
    font-size: 12px !important; }

.Button_variant20__3j81C {
  padding: 11px 15px; }
  .Button_variant20__3j81C.Button_wiz__3MbWI {
    background-color: #27bdbe;
    border-color: #27bdbe; }
    .Button_variant20__3j81C.Button_wiz__3MbWI:hover {
      background-color: #1ba6a7;
      border-color: #1ba6a7; }

.Button_variant21__Mv7nk {
  min-height: 46px !important; }

.Button_variant22__3RbdU {
  min-height: 46px !important;
  padding-left: 10px !important; }

.Button_variant23__1irI8 {
  font-size: 12px;
  line-height: 11px;
  padding: 8px 15px;
  text-transform: none;
  letter-spacing: normal;
  letter-spacing: initial;
  font-weight: 400; }
  @media (min-width: 768px) {
    .Button_variant23__1irI8:hover {
      box-shadow: 0 0; } }

.Button_variant24__HFCP8 {
  display: block !important;
  margin-top: 0;
  padding: 10px 15px;
  height: 100%; }
  .Button_variant24__HFCP8 .Button_icon__lnRSo > div {
    text-align: right !important;
    margin-bottom: 20px; }
  .Button_variant24__HFCP8 .Button_label__2PDy3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300; }
  .Button_variant24__HFCP8 .Button_description__2Xkwk {
    font-size: 11px;
    padding-bottom: 15px; }

.Button_variant26__2nqVG {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 0 !important; }
  .Button_variant26__2nqVG.Button_selected__3k-M7 {
    box-shadow: none !important; }

.Button_variant27__2eBEK {
  background-color: #ffffff;
  color: #ea5a41; }
  @media (min-width: 768px) {
    .Button_variant27__2eBEK:hover {
      background-color: #ffffff;
      color: #ea5a41; } }

.Button_variant25__1A9Tw {
  position: relative;
  border: 2px solid #fff;
  border-radius: 10px;
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3); }
  .Button_variant25__1A9Tw .Button_description__2Xkwk {
    text-transform: none;
    padding-right: 10px;
    max-width: 100%;
    font-size: 11px !important; }
  .Button_variant25__1A9Tw.Button_selected__3k-M7 {
    padding-right: 24px !important; }

.Button_iconLabel__3LZEz {
  padding: 10px 37px 10px 10px; }
  .Button_iconLabel__3LZEz .Button_icon__lnRSo {
    margin-right: 15px; }

.Button_onlyIcon__3A0lv {
  width: 64px;
  height: 64px;
  border-radius: 50% !important;
  padding: 0 !important;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .Button_onlyIcon__3A0lv .Button_label__2PDy3 {
    width: auto;
    width: initial; }

.Button_variant24__HFCP8 .Button_label__2PDy3 {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px; }

.Button_variant24__HFCP8 .Button_icon__lnRSo > div {
  text-align: left !important; }

.Button_variant24__HFCP8 .Button_description__2Xkwk {
  text-transform: none;
  font-size: 12px;
  word-break: break-all; }

.Button_variant28__1POxV {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 10px !important; }
  .Button_variant28__1POxV .Button_label__2PDy3 {
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    text-transform: none;
    text-transform: initial; }
  .Button_variant28__1POxV .Button_icon__lnRSo {
    margin: 0 0 10px; }

.Button_variant4__3QRtY {
  border-radius: 0 !important; }

@media (max-width: 768px) {
  .Button_button__1RtXl {
    padding: 9px 20px; }
  .Button_onlyIcon__3A0lv {
    padding: 11px 20px; }
  .Button_variant1__c5JQa {
    padding: 9px 27px; }
  .Button_iconLabelDescription__38Ac_ {
    position: relative; }
  .Button_variant7__1MUDZ {
    width: 50px !important;
    height: 50px !important;
    border: 0; }
    .Button_variant7__1MUDZ .Button_icon__lnRSo {
      padding: 8px;
      display: -webkit-flex;
      display: flex; }
  .Button_variant5__1vV8l {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .Button_variant5__1vV8l .Button_icon__lnRSo {
      height: 40px; }
      .Button_variant5__1vV8l .Button_icon__lnRSo img {
        height: 34px; }
    .Button_variant5__1vV8l .Button_label__2PDy3 {
      display: none; }
  .Button_variant26__2nqVG {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .Button_variant26__2nqVG .Button_label__2PDy3 {
      text-align: left; } }

@media (max-width: 479px) {
  .Button_variant1__c5JQa {
    font-size: 12px; }
  .Button_variant7__1MUDZ {
    width: 40px !important;
    height: 40px !important;
    border: 0; }
    .Button_variant7__1MUDZ .Button_icon__lnRSo {
      padding: 8px;
      display: -webkit-flex;
      display: flex; }
      .Button_variant7__1MUDZ .Button_icon__lnRSo img {
        height: 30px;
        width: 30px; } }

.Layout_row__32EVB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.Layout_fill__1P7JY {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.Layout_mainColumnSize__13zSz {
  -webkit-flex: 1 1 350px;
          flex: 1 1 350px; }

@media (max-width: 1040px) {
  .full-width-mobile > div {
    width: 100% !important;
    -webkit-flex: initial !important;
            flex: initial !important; } }

@media (max-width: 991px) {
  .full-width-mobile > div {
    width: auto !important;
    -webkit-flex: 1 1 !important;
            flex: 1 1 !important; } }

@media (max-width: 768px) {
  .full-width-mobile > div {
    width: 100% !important;
    -webkit-flex: initial !important;
            flex: initial !important; } }

.CloseIcon_root__3UmqG {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 32px;
  width: 32px;
  cursor: pointer; }
  .CloseIcon_root__3UmqG.CloseIcon_variant1__i6M-V {
    top: 24px;
    z-index: 1001; }
  .CloseIcon_root__3UmqG.CloseIcon_variant2__Qn8QN {
    height: 45px;
    width: 45px;
    padding: 9px;
    background-color: #ea5a41;
    -webkit-transition: background-color .3s;
    transition: background-color .3s; }
    .CloseIcon_root__3UmqG.CloseIcon_variant2__Qn8QN:hover {
      background-color: #c21b17; }

@media (max-width: 991px) {
  .CloseIcon_root__3UmqG.CloseIcon_variant1__i6M-V {
    top: 15px; } }

.Modal_root__YC2h2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 1000;
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border-radius: 5px;
  background-color: #333;
  overflow: auto;
  -ms-flex-align: center; }
  .Modal_root__YC2h2 .Modal_container__26fXT {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .Modal_root__YC2h2 .Modal_inner__1bJjD {
    width: 100vw;
    max-width: 550px;
    min-width: 200px;
    padding: 32px;
    margin: auto;
    -ms-flex-align: center; }
  .Modal_root__YC2h2 .Modal_close__2P5Lt {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 1001; }
  .Modal_root__YC2h2.Modal_variant1__1I8Kl {
    display: block;
    border-radius: 0; }
    .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_container__26fXT {
      width: 100%;
      height: auto;
      height: initial; }
    .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_inner__1bJjD {
      width: 100%;
      margin: 0 auto;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_inner__1bJjD > div {
        width: 100%; }
  .Modal_root__YC2h2.Modal_map__-wfRX {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0; }
    .Modal_root__YC2h2.Modal_map__-wfRX .Modal_container__26fXT {
      overflow: hidden; }
    .Modal_root__YC2h2.Modal_map__-wfRX .Modal_closeBackground__3Qbir {
      position: absolute;
      top: 0;
      width: 100%;
      height: 75px;
      background-color: #ea5a41;
      z-index: 1000; }
    .Modal_root__YC2h2.Modal_map__-wfRX .Modal_inner__1bJjD {
      padding: 0;
      height: 100%;
      max-width: 100%;
      height: 100vh; }
  .Modal_root__YC2h2.Modal_fromSummary__C64ND {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #787878 !important;
    position: relative;
    padding: 15px;
    min-height: 100vh;
    border-radius: 0; }
    .Modal_root__YC2h2.Modal_fromSummary__C64ND > .Modal_container__26fXT > .Modal_inner__1bJjD {
      padding: 0;
      max-width: 100%;
      position: relative;
      min-height: calc(100vh - 30px); }
    .Modal_root__YC2h2.Modal_fromSummary__C64ND .Modal_variant1__1I8Kl {
      padding: 13px;
      background-color: #333; }
    @media (max-width: 991px) {
      .Modal_root__YC2h2.Modal_fromSummary__C64ND {
        padding: 10px; }
        .Modal_root__YC2h2.Modal_fromSummary__C64ND > .Modal_container__26fXT > .Modal_inner__1bJjD {
          min-height: calc(100vh - 20px); } }
  .Modal_root__YC2h2.Modal_date__280e6 {
    background-color: #fff; }
    .Modal_root__YC2h2.Modal_date__280e6 .Modal_close__2P5Lt {
      background-color: #333; }
  .Modal_root__YC2h2.Modal_variant1__1I8Kl {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3); }
    .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_container__26fXT {
      position: relative;
      background-color: #fff;
      border-radius: 5px; }
    .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_inner__1bJjD {
      padding-top: 90px;
      padding-bottom: 90px;
      max-width: 500px;
      min-height: calc(100vh - 40px); }
  .Modal_root__YC2h2.Modal_popUp__wEOAr {
    background-color: rgba(0, 0, 0, 0.53);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .Modal_root__YC2h2.Modal_popUp__wEOAr .Modal_inner__1bJjD {
      background-color: #fff;
      padding: 20px 15px;
      max-width: 400px; }

@media (max-width: 991px) {
  .Modal_root__YC2h2.Modal_map__-wfRX .Modal_closeBackground__3Qbir {
    height: 60px; }
  .Modal_root__YC2h2 .Modal_inner__1bJjD {
    padding: 70px 32px;
    width: 100%; } }

@media (max-width: 768px) {
  .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_inner__1bJjD {
    padding: 70px 20px; } }

@media (max-width: 568px) {
  .Modal_root__YC2h2 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal_root__YC2h2.Modal_variant1__1I8Kl {
    padding: 0; }
    .Modal_root__YC2h2.Modal_variant1__1I8Kl .Modal_container__26fXT {
      border-radius: 0;
      height: 100%; } }

.Text_root__z_D7W {
  font-size: 16px;
  line-height: 27px;
  max-width: 100%; }
  .Text_root__z_D7W ul {
    list-style-position: outside;
    padding-left: 30px; }
  .Text_root__z_D7W li {
    padding: 6px 0;
    list-style-type: square; }
    .Text_root__z_D7W li a {
      color: #00a8f6; }
  .Text_root__z_D7W a {
    color: #333; }

.Text_missing__3HHtt {
  color: red; }

h2.Text_root__z_D7W {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif; }

h5.Text_root__z_D7W {
  margin-top: 20px;
  margin-bottom: 0; }

.Text_variant1__1N-fe {
  font-size: 22px !important;
  color: #333;
  line-height: 29px !important;
  font-weight: 500 !important;
  margin: 0 !important; }

.Text_variant2__2O9nN {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0; }

.Text_variant4__c4CC0,
.Text_variant20__Yin_P {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0; }

.Text_variant3__Uj7t5 {
  color: #333;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase; }

.Text_variant4__c4CC0 {
  color: white; }

.Text_variant5__13aGx {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px !important;
  width: 100%;
  display: inline-block; }

.Text_variant6__wL3Df {
  color: #fff; }

.Text_variant7__FkGHe {
  font-size: 13px;
  line-height: 19px; }

.Text_variant8__3_nVv {
  font-size: 14px;
  font-weight: 500; }

.Text_variant9__nQ6kR {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #333; }

.Text_variant10__1cDjQ {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 500; }

.Text_variant11__1Pq9O {
  font-size: 23px;
  font-weight: 400;
  color: white;
  margin-bottom: 5px; }

.Text_variant12__1v3_t {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  color: #333; }
  .Text_variant12__1v3_t p {
    margin: 0 0 10px; }

.Text_variant13__m2YYW {
  display: inline-block;
  margin-top: 10px;
  background-color: #333;
  color: white;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 20px;
  padding: 1px 10px; }

.Text_variant14__3hfhK {
  color: #c4c4c4 !important;
  font-size: 14px;
  line-height: 20px;
  margin: 0; }

h2.Text_variant15__3jC3I {
  color: #fff;
  font-size: 34px;
  line-height: 40px; }

.Text_variant16___wbzJ {
  font-size: 13px;
  font-weight: 500;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 3px;
  margin-top: 0;
  width: 100%; }

.Text_variant17__3TYF3 {
  font-size: 18px;
  font-weight: 300;
  color: #fff; }

.Text_variant18__b7nbc {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
  display: block; }

.Text_variant19__3auG9 {
  font-size: 21px;
  color: #c4c4c4; }

.Text_variant20__Yin_P {
  color: #c4c4c4; }

.Text_variant21__2xrrq {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  padding: 0 9px;
  color: #fff;
  border-radius: 15px; }

.Text_variant22__2m9L- {
  color: #a1a1a1;
  font-size: 11px;
  text-transform: uppercase; }

.Text_variant23__aig2U a {
  color: #c21b17; }

.Text_variant23__aig2U.Text_wiz__2D_5Z a {
  color: #27bdbe; }

.Text_variant24__1L3q2 {
  font-size: 21px;
  margin: 0; }

.Text_variant25__2srFA {
  font-size: 14px;
  line-height: 20px;
  margin: 0; }

.Text_variant26__h2xAl {
  color: #333;
  text-transform: none;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 0 0 8px;
  line-height: 1.6;
  letter-spacing: normal;
  letter-spacing: initial; }

.Text_variant27__3LGB0 {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: normal;
  letter-spacing: initial; }

.Text_variant28__eUlUO {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0; }

.Text_variant29__3oi2k {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  margin: 0 !important; }

.Text_variant30__1v3bg {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  margin: 0; }

.Text_variant31__3B2SL {
  font-size: 21px;
  margin: 0; }

.Text_variant32__1-Bf2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #333;
  word-wrap: break-word;
  width: calc(100% - 100px); }

.Text_variant33__1W5w6 {
  display: block;
  padding: 25px 0 5px;
  font-size: 19px;
  font-weight: 700;
  color: #333; }

.Text_variant34__2DeUQ {
  font-size: 14px;
  color: #333; }

.Text_variant35__QoWeS {
  font-size: 12px;
  color: #c21b17;
  margin: 0;
  line-height: 23px; }

.Text_variant36__3Ff0U {
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  margin: 0 !important; }

.Text_checkboxInverted__2htoW {
  color: #fff;
  font-size: 1rem;
  /* 16/16 */ }

@media (max-width: 991px) {
  h2.Text_root__z_D7W {
    font-size: 40px;
    line-height: 50px; } }

@media (max-width: 768px) {
  .Text_root__z_D7W {
    font-size: 14px;
    line-height: 25px; }
  h2.Text_root__z_D7W {
    font-size: 30px;
    line-height: 40px; }
  .Text_variant3__Uj7t5 {
    font-size: 11px; }
  .Text_variant10__1cDjQ {
    font-size: 22px; }
  .Text_variant11__1Pq9O {
    font-size: 23px; }
  .Text_variant12__1v3_t {
    font-size: 10px; }
  .Text_variant17__3TYF3 {
    font-size: 18px; }
  .Text_variant19__3auG9,
  .Text_variant24__1L3q2,
  .Text_variant31__3B2SL {
    font-size: 21px; }
  .Text_variant26__h2xAl,
  .Text_variant27__3LGB0,
  .Text_variant28__eUlUO {
    font-size: 13px; }
  .Text_variant27__3LGB0 {
    font-size: 15px; }
  .Text_variant29__3oi2k {
    font-size: 24px; }
  .Text_variant33__1W5w6 {
    font-size: 19px; }
  .Text_variant34__2DeUQ {
    font-size: 14px; }
  .Text_variant36__3Ff0U {
    font-size: 24px; } }

@media (max-width: 479px) {
  .Text_root__z_D7W {
    font-size: 12px;
    line-height: 23px; }
  h2.Text_root__z_D7W {
    font-size: 20px;
    line-height: 26px; }
  .Text_variant11__1Pq9O {
    font-size: 19px; }
  .Text_variant19__3auG9,
  .Text_variant24__1L3q2 {
    font-size: 21px; } }

.AutoCompleteField_container__2MJI9 {
  position: relative; }
  .AutoCompleteField_container__2MJI9 .AutoCompleteField_suggestionsContainer__2K-2j {
    position: absolute;
    background-color: white;
    color: black;
    z-index: 100;
    width: 100%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    margin-top: -4px;
    max-height: 140px;
    overflow-y: auto;
    -ms-overflow-y: scroll; }
  .AutoCompleteField_container__2MJI9 .AutoCompleteField_suggestionsList__1IXO7 {
    margin: 0;
    padding: 0; }
    .AutoCompleteField_container__2MJI9 .AutoCompleteField_suggestionsList__1IXO7 .AutoCompleteField_suggestion__2q5cT {
      list-style: none;
      padding: 5px 20px 5px 15px;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer; }
      .AutoCompleteField_container__2MJI9 .AutoCompleteField_suggestionsList__1IXO7 .AutoCompleteField_suggestion__2q5cT.AutoCompleteField_suggestionHighlighted__YwpKl {
        background-color: #eee; }

.ExclamationMark_root__2reQ2 {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #fff;
  color: #ea5a41;
  font-weight: 400;
  text-align: center; }

.ErrorMessage_root__2Awqe {
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 19px;
  position: relative; }
  .ErrorMessage_root__2Awqe > div {
    position: absolute;
    left: -1px; }

.FieldLabel_root___v_Nr {
  color: #333;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase; }
  .FieldLabel_root___v_Nr.FieldLabel_variant2__flkRQ {
    color: #8c8c8c; }

.SelectField_root__g6ylS {
  width: 100%;
  height: 55px;
  padding: 0 20px 0 15px;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #facdbc;
  border-radius: 3px;
  outline: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .SelectField_root__g6ylS::-ms-expand {
    display: none; }
  .SelectField_root__g6ylS.SelectField_invalid__m_85e {
    background-color: #fff;
    color: red; }
    .SelectField_root__g6ylS.SelectField_invalid__m_85e::-webkit-input-placeholder {
      color: red; }
    .SelectField_root__g6ylS.SelectField_invalid__m_85e:-ms-input-placeholder {
      color: red; }
    .SelectField_root__g6ylS.SelectField_invalid__m_85e::-ms-input-placeholder {
      color: red; }
    .SelectField_root__g6ylS.SelectField_invalid__m_85e::placeholder {
      color: red; }
  .SelectField_root__g6ylS:focus {
    border-radius: 3px;
    background-color: #fff;
    border-color: #ea5a41;
    color: #333; }
    .SelectField_root__g6ylS:focus::after {
      border-top-color: #ea5a41; }
  .SelectField_root__g6ylS option {
    background-color: #fff;
    color: #5f5f5f; }

.SelectField_wrapper__2iz_r {
  position: relative; }
  .SelectField_wrapper__2iz_r::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 50%;
    margin-top: -3px;
    height: 0;
    width: 0;
    border-top: 6px solid #facdbc;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    pointer-events: none; }

@media (max-width: 768px) {
  .SelectField_root__g6ylS {
    height: 43px; } }

.TextField_root__33YqT {
  width: 100%;
  height: 55px;
  padding: 0 20px 0 15px;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  background-color: transparent;
  border: 1px solid #facdbc;
  border-radius: 5px;
  outline: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  .TextField_root__33YqT.TextField_singleLine__3RXGk {
    height: 55px; }
  .TextField_root__33YqT.TextField_multiLine__1mwn_ {
    padding-top: 10px;
    height: 150px;
    min-width: 170px;
    overflow: auto; }
  .TextField_root__33YqT.TextField_variant1__3SUpB {
    height: 112px; }
  .TextField_root__33YqT.TextField_variant2__3nnIs {
    background-color: #333 !important;
    color: white;
    -webkit-box-shadow: 0 0 0 1000px #333 inset !important; }
    .TextField_root__33YqT.TextField_variant2__3nnIs:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important; }
    .TextField_root__33YqT.TextField_variant2__3nnIs:focus {
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant2__3nnIs.TextField_invalid__uSuj3 {
      color: red; }
  .TextField_root__33YqT.TextField_variant3__2cHYy {
    height: 43px;
    text-align: center; }
  .TextField_root__33YqT.TextField_variant4__1KzCi {
    color: #fff !important;
    background-color: #4c4c4c;
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
    padding: 12px;
    margin: 0 1px;
    width: calc(100% - 2px);
    border-radius: 0 !important;
    border: 6px solid #817e7e; }
    .TextField_root__33YqT.TextField_variant4__1KzCi.TextField_multiLine__1mwn_ {
      height: 110px; }
    .TextField_root__33YqT.TextField_variant4__1KzCi:focus {
      border-color: #ea5a41;
      background-color: #4c4c4c;
      color: #fff !important;
      -webkit-text-fill-color: #fff !important;
      box-shadow: none; }
  .TextField_root__33YqT.TextField_variant5__3nwyI.TextField_multiLine__1mwn_ {
    font-size: 13px;
    line-height: 22px;
    height: 80px; }
  .TextField_root__33YqT.TextField_variant6__pWCOg {
    background-color: #fff !important;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #333 !important;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3) !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg::-webkit-input-placeholder {
      color: #aaa !important;
      -webkit-text-fill-color: #aaa !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg:-ms-input-placeholder {
      color: #aaa !important;
      -webkit-text-fill-color: #aaa !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg::-ms-input-placeholder {
      color: #aaa !important;
      -webkit-text-fill-color: #aaa !important; }
    .TextField_root__33YqT.TextField_variant6__pWCOg::placeholder {
      color: #aaa !important;
      -webkit-text-fill-color: #aaa !important; }
  .TextField_root__33YqT.TextField_variant7__13iaK {
    background-color: #fff !important;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px; }
    .TextField_root__33YqT.TextField_variant7__13iaK:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      color: #333 !important;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); }
    .TextField_root__33YqT.TextField_variant7__13iaK:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: white !important; }
  .TextField_root__33YqT.TextField_variant8__3Ms9B {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #ccc;
    border-radius: 5px; }
    .TextField_root__33YqT.TextField_variant8__3Ms9B:focus {
      border-color: #3898EC;
      background-color: #fff !important;
      color: #333 !important;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); }
    .TextField_root__33YqT.TextField_variant8__3Ms9B::-webkit-input-placeholder {
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant8__3Ms9B:-ms-input-placeholder {
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant8__3Ms9B::-ms-input-placeholder {
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant8__3Ms9B::placeholder {
      color: #333 !important;
      -webkit-text-fill-color: #333 !important; }
    .TextField_root__33YqT.TextField_variant8__3Ms9B:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #333 inset;
      -webkit-text-fill-color: #fff !important; }
  .TextField_root__33YqT.TextField_invalid__uSuj3 {
    background-color: #fff;
    color: red; }
    .TextField_root__33YqT.TextField_invalid__uSuj3::-webkit-input-placeholder {
      color: red; }
    .TextField_root__33YqT.TextField_invalid__uSuj3:-ms-input-placeholder {
      color: red; }
    .TextField_root__33YqT.TextField_invalid__uSuj3::-ms-input-placeholder {
      color: red; }
    .TextField_root__33YqT.TextField_invalid__uSuj3::placeholder {
      color: red; }
    .TextField_root__33YqT.TextField_invalid__uSuj3:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      -webkit-text-fill-color: red !important; }
  .TextField_root__33YqT.TextField_disabled__14nXO {
    opacity: 0.39; }
  .TextField_root__33YqT:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    -webkit-text-fill-color: #333 !important; }
  .TextField_root__33YqT::-ms-reveal {
    background-color: #fff; }
  .TextField_root__33YqT:focus {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border-color: #ea5a41;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    -webkit-text-fill-color: #333 !important; }
  .TextField_root__33YqT::-webkit-input-placeholder {
    color: #333; }
  .TextField_root__33YqT:-ms-input-placeholder {
    color: #333; }
  .TextField_root__33YqT::-ms-input-placeholder {
    color: #333; }
  .TextField_root__33YqT::placeholder {
    color: #333; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }

::-ms-clear {
  display: none; }

@media (max-width: 991px) {
  .TextField_root__33YqT.TextField_variant8__3Ms9B {
    height: 40px; } }

@media (max-width: 768px) {
  .TextField_root__33YqT {
    height: 43px; }
    .TextField_root__33YqT.TextField_multiLine__1mwn_ {
      padding-top: 10px;
      height: 150px;
      min-width: 170px;
      overflow: auto; } }

.CheckBox_root__2ptfE {
  width: 25px;
  height: 25px;
  display: none;
  visibility: hidden; }
  .CheckBox_root__2ptfE.CheckBox_checked__2pcUR ~ .CheckBox_checkmark__1QyFj {
    background-color: #ea5a41; }
    .CheckBox_root__2ptfE.CheckBox_checked__2pcUR ~ .CheckBox_checkmark__1QyFj::after {
      opacity: 1; }

.CheckBox_checkmark__1QyFj {
  display: inline-block;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #ea5a41;
  border-radius: 3px;
  position: relative;
  top: 3px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer; }
  .CheckBox_checkmark__1QyFj::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 3px;
    width: 17px;
    height: 15px;
    background-image: url(/o/eskode-theme/static/media/checkmark-blue.cbfef57c.svg);
    background-size: cover;
    background-position: center;
    opacity: 0;
    -webkit-transition: opacity .1s;
    transition: opacity .1s; }

::-ms-clear {
  display: none; }

@media (max-width: 768px) {
  .CheckBox_root__2ptfE.CheckBox_singleLine__LUyb5 {
    height: 40px; } }

.CookieDisclaimer_gCD__2kiQh {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #c5281c;
  color: #fff;
  overflow-y: auto;
  text-align: center;
  padding: 15px;
  z-index: 9999999;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4); }
  .CookieDisclaimer_gCD__2kiQh a, .CookieDisclaimer_gCD__2kiQh a:visited, .CookieDisclaimer_gCD__2kiQh a:link, .CookieDisclaimer_gCD__2kiQh a:hover {
    color: #fff; }

.CookieDisclaimer_gCDWiz__Zkzvs {
  background-color: #fff;
  color: #000; }
  .CookieDisclaimer_gCDWiz__Zkzvs .CookieDisclaimer_gCDHeading__b-1-l {
    color: #000; }
  .CookieDisclaimer_gCDWiz__Zkzvs .CookieDisclaimer_buttonLink__3dn7O {
    color: #000; }
  .CookieDisclaimer_gCDWiz__Zkzvs a {
    color: #27bdbe !important; }

.CookieDisclaimer_gCDContainer__3xmwV {
  padding-bottom: 0 0 5px 0; }

.CookieDisclaimer_right__2QICH > * {
  margin: 5px; }

.CookieDisclaimer_gCDHeading__b-1-l {
  text-align: center;
  margin-bottom: 20px;
  font-family: Oswald, sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 1.4;
  text-transform: uppercase; }

.CookieDisclaimer_gCDText__YuVD8 {
  margin-bottom: 15px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 1rem;
  /* 16/16 */ }
  .CookieDisclaimer_gCDText__YuVD8 a, .CookieDisclaimer_gCDText__YuVD8 a:visited, .CookieDisclaimer_gCDText__YuVD8 a:link, .CookieDisclaimer_gCDText__YuVD8 a:hover {
    color: #fff; }

.CookieDisclaimer_gCDButtons__SdctY {
  text-align: center;
  width: 100%;
  max-width: 350px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 0 auto; }

.CookieDisclaimer_gCDButton__O1cdK {
  display: block;
  background-color: #fff;
  color: #000;
  padding: 15px 20px;
  border: none;
  text-transform: uppercase;
  cursor: pointer; }

/* cookie settings */
.CookieDisclaimer_gCS__3Khi9 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  background-color: #c5281c;
  color: #fff;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  max-width: 750px;
  z-index: 9999999;
  overflow-y: auto;
  margin: 0 auto; }
  .CookieDisclaimer_gCS__3Khi9 a,
  .CookieDisclaimer_gCS__3Khi9 a :visited, .CookieDisclaimer_gCS__3Khi9 a:link, .CookieDisclaimer_gCS__3Khi9 a:hover {
    color: #fff; }

.CookieDisclaimer_gCSWiz__1xwGt {
  background-color: #fff;
  color: #000; }
  .CookieDisclaimer_gCSWiz__1xwGt .CookieDisclaimer_gCSHeading__pS-sK {
    color: #000; }
  .CookieDisclaimer_gCSWiz__1xwGt .CookieDisclaimer_buttonLink__3dn7O {
    color: #000; }
  .CookieDisclaimer_gCSWiz__1xwGt p {
    color: #000; }
  .CookieDisclaimer_gCSWiz__1xwGt a {
    color: #27bdbe !important; }
  .CookieDisclaimer_gCSWiz__1xwGt .CookieDisclaimer_gCSSettings__2qU4v span {
    color: #000; }

.CookieDisclaimer_gCSContainer__5KBf3 {
  padding-bottom: 30px; }

.CookieDisclaimer_gCSHeading__pS-sK {
  font-family: Oswald, sans-serif;
  font-size: 1.75rem;
  /* 28/16 */
  line-height: 1.6;
  text-align: center;
  color: #fff; }

.CookieDisclaimer_gCSSettings__2qU4v {
  margin-bottom: 25px; }

.CookieDisclaimer_gCSContainer__5KBf3,
.CookieDisclaimer_gCDContainer__3xmwV {
  margin: 0 auto; }

.CookieDisclaimer_buttonLink__3dn7O {
  font-size: 1rem;
  /* 16/16 */
  font-weight: 400;
  color: #fff;
  text-transform: none;
  background-color: transparent;
  border: none;
  margin-top: 10px; }
  .CookieDisclaimer_buttonLink__3dn7O span {
    text-decoration: underline;
    letter-spacing: 0; }
  .CookieDisclaimer_buttonLink__3dn7O:hover {
    box-shadow: none;
    background-color: transparent; }

.CookieDisclaimer_gCSCheckbox__x_Ssu span {
  color: #fff; }

@media (min-width: 768px) {
  .CookieDisclaimer_gCD__2kiQh {
    padding-top: 35px;
    padding-bottom: 30px; }
  .CookieDisclaimer_gCDContainer__3xmwV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    text-align: left;
    -webkit-align-items: center;
            align-items: center; }
  .CookieDisclaimer_left__29tmU {
    -webkit-flex: 8 0 450px;
            flex: 8 0 450px;
    margin-right: 15px; }
  .CookieDisclaimer_right__2QICH {
    -webkit-flex: 0 0 200px;
            flex: 0 0 200px; }
  .CookieDisclaimer_gCDHeading__b-1-l {
    margin-bottom: 10px;
    margin-top: 0;
    text-align: left; }
  .CookieDisclaimer_gCDText__YuVD8 {
    max-height: none;
    overflow: visible;
    text-align: left;
    margin-bottom: 0; }
  .CookieDisclaimer_gCDButtons__SdctY {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    text-align: center;
    -webkit-flex: 1 0 100px;
            flex: 1 0 100px;
    max-width: 300px; }
  .CookieDisclaimer_gCDButton__O1cdK {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%; }
  /* cookie settings */
  .CookieDisclaimer_gCS__3Khi9 {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 2px;
    width: 100%;
    max-width: 850px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
    height: auto;
    max-height: 100vh; }
  .CookieDisclaimer_gCSContainer__5KBf3 {
    max-width: 625px;
    padding-bottom: 0; }
  .CookieDisclaimer_gCSHeading__pS-sK {
    font-size: 3.2rem; }
  .CookieDisclaimer_gCSSettings__2qU4v {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; } }

@media (min-width: 992px) {
  .CookieDisclaimer_gCSContainer__5KBf3,
  .CookieDisclaimer_gCDContainer__3xmwV {
    max-width: 1400px; } }

/* from left to right */
.SlideView_leftRight__11FEd {
  height: 100%; }
  .SlideView_leftRight__11FEd > div {
    height: 100%; }
  .SlideView_leftRight__11FEd .SlideView_enter__1n--8 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(100%);
        -ms-transform: translate(100%);
            transform: translate(100%); }
    .SlideView_leftRight__11FEd .SlideView_enter__1n--8.SlideView_enterActive__3kehz {
      -webkit-transform: translate(0%);
          -ms-transform: translate(0%);
              transform: translate(0%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_leftRight__11FEd .SlideView_exit__30CCA {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
            transform: translate(0%); }
    .SlideView_leftRight__11FEd .SlideView_exit__30CCA.SlideView_exitActive__N7OsG {
      -webkit-transform: translate(-100%);
          -ms-transform: translate(-100%);
              transform: translate(-100%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_leftRight__11FEd.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_leftRight__11FEd.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
        padding: 10px; } }
  .SlideView_leftRight__11FEd.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_leftRight__11FEd.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
        padding: 10px; } }

/* from right to left */
.SlideView_rightLeft__vl0v5 {
  height: 100%; }
  .SlideView_rightLeft__vl0v5 > div {
    height: 100%; }
  .SlideView_rightLeft__vl0v5 .SlideView_enter__1n--8 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(-100%);
        -ms-transform: translate(-100%);
            transform: translate(-100%); }
    .SlideView_rightLeft__vl0v5 .SlideView_enter__1n--8.SlideView_enterActive__3kehz {
      -webkit-transform: translate(0%);
          -ms-transform: translate(0%);
              transform: translate(0%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_rightLeft__vl0v5 .SlideView_exit__30CCA {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
            transform: translate(0%); }
    .SlideView_rightLeft__vl0v5 .SlideView_exit__30CCA.SlideView_exitActive__N7OsG {
      -webkit-transform: translate(100%);
          -ms-transform: translate(100%);
              transform: translate(100%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_rightLeft__vl0v5.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_rightLeft__vl0v5.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
        padding: 10px; } }
  .SlideView_rightLeft__vl0v5.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_rightLeft__vl0v5.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
        padding: 10px; } }

/* from top to bottom */
.SlideView_topBottom__2Uuj5 {
  height: 100%; }
  .SlideView_topBottom__2Uuj5 .SlideView_enter__1n--8 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%, 100%);
        -ms-transform: translate(0%, 100%);
            transform: translate(0%, 100%); }
    .SlideView_topBottom__2Uuj5 .SlideView_enter__1n--8.SlideView_enterActive__3kehz {
      -webkit-transform: translate(0%, 0%);
          -ms-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_topBottom__2Uuj5 .SlideView_exit__30CCA {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
            transform: translate(0%, 0%); }
    .SlideView_topBottom__2Uuj5 .SlideView_exit__30CCA.SlideView_exitActive__N7OsG {
      -webkit-transform: translate(0%, -100%);
          -ms-transform: translate(0%, -100%);
              transform: translate(0%, -100%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_topBottom__2Uuj5.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_topBottom__2Uuj5.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
        padding: 10px; } }
  .SlideView_topBottom__2Uuj5.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_topBottom__2Uuj5.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
        padding: 10px; } }

/* from bottom to top */
.SlideView_bottomTop__3OIZC {
  height: 100%; }
  .SlideView_bottomTop__3OIZC .SlideView_enter__1n--8 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%, -100%);
        -ms-transform: translate(0%, -100%);
            transform: translate(0%, -100%); }
    .SlideView_bottomTop__3OIZC .SlideView_enter__1n--8.SlideView_enterActive__3kehz {
      -webkit-transform: translate(0%, 0%);
          -ms-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_bottomTop__3OIZC .SlideView_exit__30CCA {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
            transform: translate(0%, 0%); }
    .SlideView_bottomTop__3OIZC .SlideView_exit__30CCA.SlideView_exitActive__N7OsG {
      -webkit-transform: translate(0%, 100%);
          -ms-transform: translate(0%, 100%);
              transform: translate(0%, 100%);
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out; }
  .SlideView_bottomTop__3OIZC.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_bottomTop__3OIZC.SlideView_fromSummary__3rUUq .SlideView_enter__1n--8 {
        padding: 10px; } }
  .SlideView_bottomTop__3OIZC.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
    padding: 15px; }
    @media (max-width: 991px) {
      .SlideView_bottomTop__3OIZC.SlideView_fromSummary__3rUUq .SlideView_exit__30CCA {
        padding: 10px; } }

.StepView_root__1I37d {
  height: 100%; }

.StepView_single__38Yw0 {
  height: 100%; }

.SplitView_root__-Vlqh {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-justify-content: center;
          justify-content: center; }

.SplitView_left__3rGyc, .SplitView_right__2cCiT {
  -webkit-flex: 1 1;
          flex: 1 1;
  -ms-flex-align: center; }

.SplitView_left__3rGyc {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 155px;
  padding-bottom: 120px;
  background-color: white;
  border-right: 1px solid #c4c4c4;
  border-bottom: 6px solid #a71c34;
  height: 100vh;
  overflow: auto; }
  .SplitView_left__3rGyc .SplitView_inner__64jDW {
    margin: auto 5vw;
    width: 40vw; }

.SplitView_right__2cCiT {
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
  overflow: auto; }
  .SplitView_right__2cCiT .SplitView_inner__64jDW {
    display: -webkit-flex;
    display: flex;
    width: 100%; }
  .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_inner__64jDW {
    -ms-flex-align: center;
    min-height: 50px;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px; }
  .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_ieInner__3Lep- {
    min-height: auto; }
  .SplitView_right__2cCiT.SplitView_map__7zG20 {
    display: block; }
  .SplitView_right__2cCiT.SplitView_map__7zG20 .SplitView_inner__64jDW {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%; }
  .SplitView_right__2cCiT.SplitView_isMobile__fvcrI.SplitView_map__7zG20 {
    overflow: visible;
    overflow: initial; }
    .SplitView_right__2cCiT.SplitView_isMobile__fvcrI.SplitView_map__7zG20 .SplitView_inner__64jDW {
      -webkit-justify-content: center;
              justify-content: center;
      overflow-x: visible;
      overflow-x: initial; }
  .SplitView_right__2cCiT.SplitView_centerPosition__19rKo .SplitView_inner__64jDW > div {
    width: 100%; }
  .SplitView_right__2cCiT.SplitView_maintenance__2eC9q {
    background-color: #c21b17; }
    .SplitView_right__2cCiT.SplitView_maintenance__2eC9q .SplitView_inner__64jDW {
      text-align: center; }

.SplitView_fromSummary__33w_G .SplitView_left__3rGyc, .SplitView_fromSummary__33w_G .SplitView_right__2cCiT {
  height: calc(100vh - 30px);
  border-bottom: 0; }

.SplitView_spacing__2YSqu {
  padding-top: 5vh;
  padding-bottom: 5vh;
  overflow-x: hidden; }

.SplitView_reverseFlex__1N_j3 .SplitView_left__3rGyc {
  border-bottom: none; }

.SplitView_spacerRight__2Yarb {
  -webkit-flex: 1 1 190px;
          flex: 1 1 190px;
  max-width: 180px;
  width: 100%; }

.SplitView_spacerLeft__3HGy_ {
  display: none; }

.SplitView_buttonContainer__1fqfN {
  position: absolute;
  top: 5vh;
  right: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .SplitView_buttonContainer__1fqfN > * {
    margin-bottom: 5px; }

@media (max-height: 579px) {
  .SplitView_centerPosition__19rKo .SplitView_inner__64jDW {
    -webkit-flex-direction: unset !important;
            flex-direction: unset !important; } }

@media (max-width: 991px) {
  .SplitView_root__-Vlqh {
    display: block;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    min-height: 100vh; }
    .SplitView_root__-Vlqh.SplitView_fromSummary__33w_G {
      min-height: calc(100vh - 20px);
      display: -webkit-flex;
      display: flex; }
  .SplitView_left__3rGyc {
    display: block;
    padding-top: 130px;
    padding-bottom: 60px;
    height: auto;
    height: initial;
    overflow: visible;
    overflow: initial;
    min-height: 250px;
    border-bottom: 1px solid #facdbc;
    -webkit-flex: 0 1;
            flex: 0 1; }
    .SplitView_left__3rGyc .SplitView_inner__64jDW {
      margin: 0 5vw;
      width: auto;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
  .SplitView_right__2cCiT {
    display: block;
    height: auto;
    height: initial;
    overflow: visible;
    overflow: initial; }
    .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_inner__64jDW {
      padding: 0 5vw;
      margin: 0;
      max-width: none;
      width: 100%; }
    .SplitView_right__2cCiT.SplitView_map__7zG20 {
      height: auto;
      height: initial; }
      .SplitView_right__2cCiT.SplitView_map__7zG20 .SplitView_inner__64jDW {
        overflow-x: visible;
        overflow-x: initial; }
    .SplitView_right__2cCiT.SplitView_maintenance__2eC9q {
      height: auto !important;
      -webkit-flex: 0 1 !important;
              flex: 0 1 !important;
      padding: 40px 0 !important;
      min-height: 250px !important; }
      .SplitView_right__2cCiT.SplitView_maintenance__2eC9q .SplitView_inner__64jDW {
        text-align: right; }
  .SplitView_fromSummary__33w_G .SplitView_left__3rGyc, .SplitView_fromSummary__33w_G .SplitView_right__2cCiT {
    height: auto; }
  .SplitView_spacing__2YSqu {
    padding-top: 20px;
    padding-bottom: 60px; }
  .SplitView_backgroundImg__W8S9g {
    height: 200px;
    position: relative; }
  .SplitView_reverseFlex__1N_j3 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    background-color: #fff; }
    .SplitView_reverseFlex__1N_j3 .SplitView_left__3rGyc {
      -webkit-flex: 1 1;
              flex: 1 1;
      padding-top: 60px; }
    .SplitView_reverseFlex__1N_j3 .SplitView_right__2cCiT {
      -webkit-flex: 0 1;
              flex: 0 1;
      min-height: 200px;
      height: 50vh;
      -webkit-flex: auto;
              flex: auto; }
  .SplitView_spacerLeft__3HGy_, .SplitView_spacerRight__2Yarb {
    display: block;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    max-width: none;
    width: auto; }
  .SplitView_buttonContainer__1fqfN {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    width: 100%;
    border-top: 1px solid #c4c4c4;
    background-color: #fff; }
    .SplitView_buttonContainer__1fqfN > * {
      margin: 5px; } }

@media (max-width: 768px) {
  .SplitView_left__3rGyc {
    padding-bottom: 30px; }
  .SplitView_spacing__2YSqu {
    padding-top: 20px;
    padding-bottom: 40px; }
  .SplitView_left__3rGyc .SplitView_inner__64jDW {
    margin: 0 45px; }
  .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_inner__64jDW {
    margin: 0;
    padding: 0 45px; }
    .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_inner__64jDW > div {
      margin-top: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      margin-left: 0 !important; } }

@media (max-width: 479px) {
  .SplitView_left__3rGyc .SplitView_inner__64jDW {
    margin: 0 20px; }
  .SplitView_right__2cCiT.SplitView_spacing__2YSqu .SplitView_inner__64jDW {
    margin: 0;
    padding: 0 20px; }
  .SplitView_right__2cCiT.SplitView_map__7zG20 .SplitView_inner__64jDW {
    margin: 0; }
  .SplitView_right__2cCiT.SplitView_maintenance__2eC9q {
    min-height: 180px !important; }
    .SplitView_right__2cCiT.SplitView_maintenance__2eC9q .SplitView_inner__64jDW {
      text-align: left; }
  .SplitView_reverseFlex__1N_j3 .SplitView_left__3rGyc {
    padding-top: 10px; }
  .SplitView_reverseFlex__1N_j3 .SplitView_right__2cCiT {
    height: 300px; } }

.ThankYouStepContainer_icon__1RmOb {
  padding: 25px;
  width: 90px;
  height: 90px;
  background-color: #facdbc; }
  .ThankYouStepContainer_icon__1RmOb img {
    width: 100%; }

.ThankYouStepContainer_title__3EexC {
  margin-bottom: 20px !important; }

.ThankYouStepContainer_subTitle__25uHM {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding-bottom: 58px; }

.ThankYouStepContainer_text__g85xA {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0; }

.ToggleButton_choice__1qLtc {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  color: #333;
  border: 2px solid #facdbc;
  border-radius: 3px;
  margin-top: 0;
  min-height: 60px;
  height: 100%;
  padding: 10px 37px 10px 10px; }
  .ToggleButton_choice__1qLtc:hover {
    background-color: #facdbc;
    border-color: #facdbc;
    color: #333; }

.ToggleButton_variant3__3VmRh {
  border: 0;
  border-radius: 0 !important;
  box-shadow: none !important; }

.ToggleButton_variant4__1v_ow {
  border: 0;
  border-radius: 0 !important;
  border-right: 1px solid #252525;
  border-bottom: 1px solid #252525;
  box-shadow: none !important; }

.ToggleButton_variant19__fRb0y {
  display: block; }

@media (max-width: 479px) {
  .ToggleButton_choice__1qLtc {
    min-height: 51px;
    font-size: 11px; } }

.Asdom_root___CmJN {
  font-family: Asdom, sans-serif;
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  color: #ea5a41;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s; }

.Asdom_variant1__15q_M {
  font-size: 24px;
  height: 24px;
  line-height: 24px; }

.Asdom_variant2__1di1u {
  font-size: 20px;
  height: 20px;
  line-height: 20px; }

.FontIcon_root__3pYHr {
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  color: #ea5a41;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.FontIcon_tocaPoplavaSteklo__15g6S {
  font-family: "Generali-toca-poplava-steklo", sans-serif; }

.FontIcon_asDom__YdewC {
  font-family: "Asdom", sans-serif; }

.FontIcon_domOseba__1jfgX {
  font-family: "DOM-oseba", sans-serif; }

.FontIcon_repairService__2lrYU {
  font-family: "repairService", sans-serif; }

.FontIcon_icoMoon__23l8e {
  font-family: "Icomoon", sans-serif; }

.FontIcon_variant1__2qeap {
  font-size: 24px;
  height: 24px;
  line-height: 24px; }

.FontIcon_variant2__pKelG {
  font-size: 20px;
  height: 20px;
  line-height: 20px; }

.FontIcon_variant3__3cdVY {
  font-size: 15px;
  height: 10px;
  line-height: 12px; }

.IcoMoon_root__1rjQB {
  color: #ea5a41;
  font-family: Icomoon, sans-serif;
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  text-align: left; }

.IcoMoon_variant1__3-qIn {
  font-size: 15px;
  height: 10px;
  line-height: 12px; }

.ToggleView_root__vDBhh {
  overflow: hidden;
  -webkit-transition: max-height 200ms ease-in-out;
  transition: max-height 200ms ease-in-out; }

.ToggleView_invisible__1v0ke {
  max-height: 0; }

.CarDamageTypeStepContainer_otherInput__2c0Sq {
  max-width: 400px;
  width: 100%; }

.CarDamageTypeStepContainer_halfWidthColumn__E6ReQ {
  width: 100% !important; }

@media (min-width: 768px) {
  .CarDamageTypeStepContainer_halfWidthColumn__E6ReQ {
    max-width: 50%;
    min-width: 50%; } }

@media (min-width: 992px) {
  .CarDamageTypeStepContainer_halfWidthColumn__E6ReQ {
    max-width: none; } }

@media (min-width: 1200px) {
  .CarDamageTypeStepContainer_halfWidthColumn__E6ReQ {
    max-width: 50%; } }

@media (max-width: 479px) {
  .CarDamageTypeStepContainer_root__1XXes.CarDamageTypeStepContainer_singleLine__2GcH0 {
    width: auto; } }

@media (min-width: 992px) and (max-width: 1474px) {
  .CarDamageTypeStepContainer_ieLgScreen__1ieXX {
    padding-bottom: 450px; } }

.ProgressBar_root__1-QNw {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex: 1 1;
          flex: 1 1;
  clear: both;
  min-height: 50px; }

.ProgressBar_step__2y1n8 {
  position: relative;
  height: 50%;
  -webkit-flex: 1 1;
          flex: 1 1; }
  .ProgressBar_step__2y1n8:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #fff;
    opacity: .4; }
  .ProgressBar_step__2y1n8:last-child {
    height: 100%; }
    .ProgressBar_step__2y1n8:last-child:after {
      height: 100%;
      opacity: 1; }

.ProgressBar_active__2r8AY {
  height: 100%;
  background-color: #a71c34;
  border-right: none;
  opacity: 1; }
  .ProgressBar_active__2r8AY:after {
    height: 50%; }
  .ProgressBar_active__2r8AY.ProgressBar_wiz__dvF_i {
    background-color: #d5cc26; }

.ProgressBar_lastActive__3qXwh:after {
  height: 100%;
  width: 2px;
  opacity: 1; }

@media (max-width: 479px) {
  .ProgressBar_root__1-QNw {
    min-height: 40px; } }

.Header_root__UI8CX {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 500px; }
  .Header_root__UI8CX.Header_fromSummary__2lp5I {
    visibility: hidden; }
    .Header_root__UI8CX.Header_fromSummary__2lp5I.Header_hide__3VaDN {
      display: none; }

.Header_wrapper__23Uqd {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  padding-right: 150px;
  background-color: #c11d0d; }
  .Header_wrapper__23Uqd.Header_fromSummary__2lp5I {
    margin: 0 5vw;
    background-color: #fff;
    padding-top: 30px;
    width: auto;
    width: initial; }
    .Header_wrapper__23Uqd.Header_fromSummary__2lp5I .Header_back__3CIWv {
      border-radius: 50%;
      background-color: #ea5a41; }

.Header_count__4gqrA {
  display: inline-block;
  color: #333;
  font-size: 30px;
  line-height: 46px;
  font-weight: 200;
  float: left; }

.Header_back__3CIWv {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 9px;
  border: 0;
  border-radius: 0;
  background-color: #ea5a41;
  color: #fff;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  .Header_back__3CIWv img {
    width: 31px;
    height: 32px;
    margin-right: 5px; }
  @media (min-width: 768px) {
    .Header_back__3CIWv:hover {
      background-color: #c21b17; } }

.Header_close__DDoj9 {
  background-color: red; }
  .Header_close__DDoj9 img {
    margin-right: 0; }
  @media (min-width: 768px) {
    .Header_close__DDoj9:hover {
      background-color: red; } }

.Header_illustration__Qs1mK {
  position: absolute;
  left: auto;
  top: 20px;
  right: 0%;
  bottom: auto;
  width: 115px; }

@media (max-width: 479px) {
  .Header_wrapper__23Uqd {
    padding-right: 0; }
  .Header_illustration__Qs1mK {
    width: 101px; } }

.CommonDatePicker_wrapper__1i8W6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 30px 0; }

.react-datepicker {
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  padding: 0 15px;
  border: 0; }
  @media (min-width: 992px) and (max-width: 1230px) {
    .react-datepicker {
      width: 100%;
      max-width: 400px; } }
  .react-datepicker .react-datepicker__header {
    background-color: #fff;
    border: 0; }
  .react-datepicker .react-datepicker__day--disabled:hover {
    background-color: transparent !important;
    border-color: #facdbc !important; }
  .react-datepicker .react-datepicker__day--disabled.react-datepicker__day--in-range.react-datepicker__day--outside-month {
    background-color: #fff; }
    .react-datepicker .react-datepicker__day--disabled.react-datepicker__day--in-range.react-datepicker__day--outside-month:hover {
      background-color: #fff; }
  .react-datepicker .react-datepicker__navigation {
    border: 0;
    height: 29px;
    width: 29px;
    background-size: 29px 29px;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
    .react-datepicker .react-datepicker__navigation--previous {
      background-image: url(/o/eskode-theme/static/media/left-arrow-grey.aa0464a7.svg);
      left: 25px; }
    .react-datepicker .react-datepicker__navigation--next {
      background-image: url(/o/eskode-theme/static/media/right-arrow-grey.c25d5ce0.svg);
      right: 25px; }
  .react-datepicker .react-datepicker__current-month {
    display: block;
    margin-bottom: 10px;
    padding-right: 51px;
    padding-left: 51px;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    color: #333;
    font-weight: 400; }
  .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown--scrollable {
    background-color: #666;
    border-color: #666;
    color: #333;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown--scrollable::-webkit-scrollbar {
      width: 0;
      height: 0; }
  .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-read-view {
    padding-right: 17px;
    position: relative; }
    .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-read-view--down-arrow {
      position: absolute;
      right: 0; }
  .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-option:hover {
    background-color: #888 !important; }
  .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-read-view--selected-year {
    font-size: 14px;
    line-height: 23px;
    color: #333; }
  .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container {
    position: relative; }
    .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select {
      padding: 5px 25px 5px 20px;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      color: #333;
      border: 0;
      outline: 0px;
      -moz-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select::-ms-expand {
        display: none; }
      .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select:focus {
        outline: 0; }
      .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container select option {
        background-color: #fff;
        color: #5f5f5f;
        font-size: 13px; }
    .react-datepicker .react-datepicker__header__dropdown .react-datepicker__year-dropdown-container::after {
      content: "";
      position: absolute;
      z-index: 2;
      right: 13px;
      top: 50%;
      margin-top: -3px;
      height: 0;
      width: 0;
      border-top: 6px solid #5f5f5f;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      pointer-events: none; }
  @media (min-width: 992px) and (max-width: 1230px) {
    .react-datepicker .react-datepicker__month-container {
      width: 100%; } }
  @media (min-width: 992px) and (max-width: 1230px) {
    .react-datepicker .react-datepicker__week {
      display: -webkit-flex;
      display: flex; } }
  .react-datepicker .react-datepicker__day-name {
    color: #333;
    font-size: 12px;
    width: 38px;
    line-height: 29px;
    margin-left: 5px;
    margin-right: 10px; }
    @media (max-width: 425px) {
      .react-datepicker .react-datepicker__day-name {
        width: 31px;
        line-height: 31px;
        font-size: 15px;
        margin-right: 5px; } }
    @media (min-width: 992px) and (max-width: 1230px) {
      .react-datepicker .react-datepicker__day-name {
        -webkit-flex: 1 1 14%;
                flex: 1 1 14%;
        width: auto; } }
  .react-datepicker .react-datepicker__day {
    font-size: 17px;
    color: #333;
    width: 38px;
    line-height: 36px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #facdbc;
    background-color: rgba(255, 255, 255, 0.11);
    -webkit-transition: all .3s;
    transition: all .3s; }
    @media (max-width: 425px) {
      .react-datepicker .react-datepicker__day {
        width: 31px;
        line-height: 31px;
        font-size: 15px;
        margin-right: 5px; } }
    @media (min-width: 992px) and (max-width: 1230px) {
      .react-datepicker .react-datepicker__day {
        -webkit-flex: 1 1 14%;
                flex: 1 1 14%;
        width: auto;
        line-height: 120%;
        padding-top: 1.5%;
        padding-bottom: 1.5%; } }
    .react-datepicker .react-datepicker__day:hover {
      border-radius: 50%;
      background-color: #facdbc; }
    .react-datepicker .react-datepicker__day--selected {
      color: #fff;
      background-color: #c21b17 !important;
      border-color: #c21b17; }
    .react-datepicker .react-datepicker__day--outside-month {
      opacity: 0.42; }
      .react-datepicker .react-datepicker__day--outside-month:hover {
        background-color: #fff !important; }
    .react-datepicker .react-datepicker__day--in-range {
      color: #fff;
      background-color: #c21b17 !important;
      border-color: #c21b17 !important; }
      .react-datepicker .react-datepicker__day--in-range:hover {
        background-color: #c21b17 !important;
        border-color: #c21b17 !important; }
    .react-datepicker .react-datepicker__day--in-selecting-range {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 50%; }
      .react-datepicker .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-selecting-range) {
        color: #00a8f6; }
  @media (max-width: 768px) {
    .react-datepicker {
      margin-top: 20px; } }
  @media (max-width: 400px) {
    .react-datepicker {
      padding-left: 0;
      padding-right: 0; } }

.react-datepicker-popper .react-datepicker {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14); }
  .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important; }
    .react-datepicker-popper .react-datepicker .react-datepicker__triangle:before {
      display: none;
      top: 0 !important;
      border-bottom-color: #fff !important; }
  .react-datepicker-popper .react-datepicker .react-datepicker__day {
    color: #333;
    border: 1px solid #e7e7e7; }
    .react-datepicker-popper .react-datepicker .react-datepicker__day:hover {
      background-color: #facdbc; }
  .react-datepicker-popper .react-datepicker .react-datepicker__current-month {
    color: #333; }
  .react-datepicker-popper .react-datepicker .react-datepicker__year-read-view--selected-year {
    color: #333; }
  .react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown--scrollable {
    background-color: #fff;
    border-color: #e7e7e7;
    color: #333; }
  .react-datepicker-popper .react-datepicker .react-datepicker__year-option:hover {
    background-color: #f1f1f1 !important; }
  .react-datepicker-popper .react-datepicker .react-datepicker__header {
    background-color: #fff; }
  .react-datepicker-popper .react-datepicker .react-datepicker__day-name {
    color: #c4c4c4; }
  .react-datepicker-popper .react-datepicker .react-datepicker__day--selected {
    color: #fff;
    background-color: #c21b17 !important;
    border-color: #c21b17; }
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation--previous {
    background-image: url(/o/eskode-theme/static/media/left-arrow-grey.aa0464a7.svg); }
  .react-datepicker-popper .react-datepicker .react-datepicker__navigation--next {
    background-image: url(/o/eskode-theme/static/media/right-arrow-grey.c25d5ce0.svg); }

.CommonDatePicker_modal__ldwAz .react-datepicker {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14); }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__triangle {
    border-bottom-color: #fff !important;
    border-top-color: #fff !important; }
    .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__triangle:before {
      display: none;
      top: 0 !important;
      border-bottom-color: #fff !important; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__day {
    color: #333;
    border: 1px solid #e7e7e7; }
    .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__day:hover {
      background-color: #facdbc; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__current-month {
    color: #333; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__year-read-view--selected-year {
    color: #333; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__year-dropdown--scrollable {
    background-color: #fff;
    border-color: #e7e7e7;
    color: #333; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__year-option:hover {
    background-color: #f1f1f1 !important; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__header {
    background-color: #fff; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__day-name {
    color: #c4c4c4; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__day--selected {
    color: #fff;
    background-color: #c21b17 !important;
    border-color: #c21b17; }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__navigation--previous {
    background-image: url(/o/eskode-theme/static/media/left-arrow-grey.aa0464a7.svg); }
  .CommonDatePicker_modal__ldwAz .react-datepicker .react-datepicker__navigation--next {
    background-image: url(/o/eskode-theme/static/media/right-arrow-grey.c25d5ce0.svg); }

.DateRangePicker_column__k4G_y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .DateRangePicker_column__k4G_y .DateRangePicker_header__XlSye {
    text-align: center;
    font-weight: 700; }

.FadeView_root___4IAy {
  position: relative; }

.FadeView_enter__1EpHm {
  opacity: 0; }
  .FadeView_enter__1EpHm.FadeView_enterActive__3QNOz {
    opacity: .99;
    -webkit-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out; }

.FadeView_exit__2AU0y {
  opacity: .99; }
  .FadeView_exit__2AU0y.FadeView_exitActive__4CkPf {
    opacity: 0;
    -webkit-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out; }

.Link_root__f4yg2 {
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  outline: none;
  background-color: #ea5a41;
  padding: 4px 10px;
  border-radius: 2px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease; }
  .Link_root__f4yg2:hover {
    background-color: #c21b17 !important;
    border-color: #c21b17 !important;
    color: #fff !important; }
  .Link_root__f4yg2.Link_variant2__2wng6 {
    color: #333;
    text-align: left;
    text-decoration: underline;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    max-width: calc(100% - 90px); }
  .Link_root__f4yg2.Link_variant1__3-xHp {
    font-size: 14px;
    background-color: #fff;
    color: #ea5a41; }
  .Link_root__f4yg2.Link_variant2__2wng6 {
    background-color: #fff;
    color: #ea5a41;
    font-size: 11px; }
    .Link_root__f4yg2.Link_variant2__2wng6:hover {
      background-color: #fff !important;
      border-color: #fff !important;
      color: #ea5a41 !important; }
  .Link_root__f4yg2.Link_variant3__cgH4M {
    background-color: transparent;
    color: #333;
    font-size: 11px;
    font-weight: 600; }
    .Link_root__f4yg2.Link_variant3__cgH4M:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #333 !important; }
  .Link_root__f4yg2.Link_variant4__1sMdI {
    background-color: transparent;
    color: #ea5a41;
    font-size: 14px;
    padding-left: 0; }
    .Link_root__f4yg2.Link_variant4__1sMdI:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #ea5a41 !important; }
  .Link_root__f4yg2.Link_variant5__8q-ye {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    max-width: calc(100% - 28px);
    width: 100%;
    background-color: transparent;
    color: #333;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 10px 4px 2px; }
    .Link_root__f4yg2.Link_variant5__8q-ye p {
      word-break: break-all;
      max-width: calc(100% - 43px); }
    .Link_root__f4yg2.Link_variant5__8q-ye:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #333 !important; }

.Map_container__1NXfc {
  height: 100%;
  min-height: 500px;
  position: relative;
  margin-bottom: 40px; }

.Map_container__1NXfc.Map_isMobile__3PxI1 {
  margin-bottom: 0; }
  .Map_container__1NXfc.Map_isMobile__3PxI1 .Map_icon__jFn3m {
    display: none; }

.Map_icon__jFn3m {
  position: absolute;
  left: 0px;
  bottom: -25px;
  right: 0px;
  display: block;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  padding: 8px;
  border-radius: 50%;
  background-color: #333; }

@media (max-width: 991px) {
  .Map_container__1NXfc {
    margin-bottom: 20px; } }

.SelectedLocationText_root__2JzFm {
  position: relative;
  width: 100%;
  padding-right: 35px;
  display: inline-block;
  margin-bottom: 10px; }
  .SelectedLocationText_root__2JzFm .SelectedLocationText_text__1hfP6 {
    width: 100%; }
  .SelectedLocationText_root__2JzFm .SelectedLocationText_delete__3HTEj {
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
    width: 24px; }
  .SelectedLocationText_root__2JzFm span {
    max-width: 50px; }

.VehicleFontIcon_root__1pwFc {
  color: #ea5a41;
  font-size: 35px;
  line-height: 35px;
  font-weight: 400; }
  .VehicleFontIcon_root__1pwFc.VehicleFontIcon_vehicle__3hF4- {
    font-family: "As vozila", sans-serif; }
  .VehicleFontIcon_root__1pwFc.VehicleFontIcon_company__3uBAI {
    font-family: "AS-ICONS-3", sans-serif; }

.SvgField_root__7F55G > svg {
  max-width: 350px; }

.SvgField_root__7F55G.SvgField_car__3_OC4 {
  overflow: hidden; }
  .SvgField_root__7F55G.SvgField_car__3_OC4 > svg {
    min-height: 350px; }

.SvgField_root__7F55G.SvgField_semiTruck___HB5D {
  overflow: hidden; }
  .SvgField_root__7F55G.SvgField_semiTruck___HB5D > svg {
    min-height: 350px; }

.RemoveIcon_root__3nx5h {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #ea5a41;
  color: #fff;
  font-weight: 500;
  text-align: center; }
  .RemoveIcon_root__3nx5h.RemoveIcon_light__3mLng {
    background-color: #fff;
    color: #00a8f6; }
  .RemoveIcon_root__3nx5h.RemoveIcon_dark__-ByvZ {
    background-color: #00a8f6;
    color: #fff; }

.RemoveButton_root__35n47 {
  margin-top: 10px;
  margin-bottom: 20px; }
  .RemoveButton_root__35n47.RemoveButton_light__1YIqa {
    color: #fff; }
  .RemoveButton_root__35n47.RemoveButton_dark__BDfHA {
    color: #00a8f6; }

.FormErrorMessage_root__1x-MV {
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px; }

.FormErrorMessage_wrapper__1URlk {
  position: relative;
  min-height: 60px;
  margin-top: 20px;
  padding: 15px 20px 18px 50px;
  border-radius: 3px;
  background-color: #facdbc;
  color: #333;
  font-size: 12px; }

.FormErrorMessage_img__hAdFs {
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 10px; }
  .FormErrorMessage_img__hAdFs img {
    width: 29px;
    height: 29px; }

.FormErrorMessage_list__FA1Gg {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #333 !important; }

.UploadButton_input__3fvaX {
  display: none; }

.UploadButton_icon__1KxLN {
  display: -webkit-flex;
  display: flex; }
  .UploadButton_icon__1KxLN .UploadButton_label__3ijGE {
    -webkit-flex: 1 1;
            flex: 1 1; }

.DocumentsIcon_root__1IfvV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 47%;
          flex: 1 1 47%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 8px 0 8px;
  max-width: 47%;
  border-style: solid;
  border-width: 1px;
  border-color: #ea5a41;
  background-color: #facdbc;
  -webkit-transition: background-color .3s;
  transition: background-color .3s; }
  .DocumentsIcon_root__1IfvV:hover {
    background-color: #ea5a41; }
  .DocumentsIcon_root__1IfvV .DocumentsIcon_icon__dkNCN {
    width: 33px;
    height: 33px;
    margin-right: 10px; }
  .DocumentsIcon_root__1IfvV .DocumentsIcon_delete__12rQ6 {
    cursor: pointer;
    width: 18px;
    margin: 5px; }

@media (max-width: 479px) {
  .DocumentsIcon_root__1IfvV {
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    max-width: 100%; } }

.VehicleIcon_root__3NJ-F {
  border-radius: 5px;
  color: #fff;
  line-height: 47px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  background-color: #999; }
  .VehicleIcon_root__3NJ-F .VehicleIcon_iconContainer__1zLZY {
    padding-bottom: 0;
    font-weight: 400; }
  .VehicleIcon_root__3NJ-F .VehicleIcon_icon__39WCN {
    color: #fff; }
  .VehicleIcon_root__3NJ-F.VehicleIcon_variant1__mhRC5 {
    width: 59px;
    height: 92px;
    font-size: 35px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant1__mhRC5 .VehicleIcon_iconContainer__1zLZY {
      padding-top: 11px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant1__mhRC5 .VehicleIcon_iconContainer__1zLZY, .VehicleIcon_root__3NJ-F.VehicleIcon_variant1__mhRC5 .VehicleIcon_icon__39WCN {
      line-height: 35px;
      font-size: 35px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant1__mhRC5 .VehicleIcon_name__2yZK8 {
      padding-top: 5px;
      font-size: 28px;
      line-height: 31px; }
  .VehicleIcon_root__3NJ-F.VehicleIcon_variant2__359ja {
    width: 35px;
    height: 54px;
    font-size: 20px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant2__359ja .VehicleIcon_iconContainer__1zLZY {
      padding-top: 8px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant2__359ja .VehicleIcon_iconContainer__1zLZY, .VehicleIcon_root__3NJ-F.VehicleIcon_variant2__359ja .VehicleIcon_icon__39WCN {
      line-height: 20px;
      font-size: 20px; }
    .VehicleIcon_root__3NJ-F.VehicleIcon_variant2__359ja .VehicleIcon_name__2yZK8 {
      padding-top: 3px;
      font-size: 20px;
      line-height: 23px; }

.VehicleInfoHeader_title__1WdWl {
  margin-bottom: 20px !important; }

.VehicleInfoHeader_spacingBorder__sf-Qx {
  border-bottom: 1px solid #333; }

.VehicleInfo_root__38WmI {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding-bottom: 0;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  padding: 5px 0 10px; }

.VehicleInfo_icon__5ddyo {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10px 5px 10px 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

.VehicleInfo_list__38hHL {
  list-style: none;
  text-transform: none;
  margin-top: 0;
  padding: 15px 10px 15px;
  margin-bottom: 0;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px; }

.VehicleInfo_line__1wWd8 {
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  line-height: 16px; }
  .VehicleInfo_line__1wWd8 .VehicleInfo_column__1zDaC {
    padding-right: 10px;
    font-weight: 400; }

.VehicleInfo_lineValue__1y1u8 {
  font-weight: 700; }

.VehicleInfo_variant2__2mWnu,
.VehicleInfo_variant3___y5eD {
  border-top: 0;
  border-bottom: 0;
  border-radius: 7px;
  padding: 0;
  overflow: hidden;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease; }
  .VehicleInfo_variant2__2mWnu .VehicleInfo_icon__5ddyo,
  .VehicleInfo_variant3___y5eD .VehicleInfo_icon__5ddyo {
    padding: 20px 15px 15px;
    background-color: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    border: 1px solid white;
    background-color: transparent;
    border-width: 0; }
  .VehicleInfo_variant2__2mWnu .VehicleInfo_list__38hHL,
  .VehicleInfo_variant3___y5eD .VehicleInfo_list__38hHL {
    background-color: transparent;
    color: #333; }
    .VehicleInfo_variant2__2mWnu .VehicleInfo_list__38hHL > li:nth-child(2),
    .VehicleInfo_variant3___y5eD .VehicleInfo_list__38hHL > li:nth-child(2) {
      margin-top: 10px; }
  .VehicleInfo_variant2__2mWnu:hover,
  .VehicleInfo_variant3___y5eD:hover {
    background-color: #facdbc; }

.VehicleInfo_variant3___y5eD {
  background-color: #facdbc; }

.VehicleInfo_variant4__3Nmbb {
  margin-top: 20px;
  padding: 15px 20px 18px;
  border-radius: 3px;
  background-color: #facdbc;
  border: none !important; }

@media (max-width: 479px) {
  .VehicleInfo_line__1wWd8 .VehicleInfo_column__1zDaC {
    width: 100%;
    padding-bottom: 4px;
    float: none; } }

.PhotosIcon_root__QtAU4 {
  position: relative;
  width: 100%;
  max-width: calc(25% - 20px);
  display: inline-block;
  margin: 0 10px 15px; }
  .PhotosIcon_root__QtAU4 .PhotosIcon_image__2SJ2U {
    width: 100%; }
  .PhotosIcon_root__QtAU4 .PhotosIcon_delete__3KUm_ {
    position: absolute;
    top: 0;
    right: 0;
    background: #ea5a41;
    cursor: pointer;
    width: 24px; }

@media (max-width: 479px) {
  .PhotosIcon_root__QtAU4 {
    max-width: calc(50% - 20px); } }

.MapSearchForm_root__2cTjn {
  position: relative;
  margin-bottom: 30px;
  height: 100%;
  min-height: 500px; }

.MapSearchForm_root__2cTjn.MapSearchForm_isMobile__zT2hn {
  height: 100vh;
  margin-bottom: 0px; }
  .MapSearchForm_root__2cTjn.MapSearchForm_isMobile__zT2hn .MapSearchForm_search__E0I89 {
    width: calc(100% - 120px);
    top: 9px;
    left: 10px; }

.MapSearchForm_search__E0I89 {
  position: absolute;
  z-index: 10000;
  top: 9px;
  left: 40px;
  min-width: 50%; }

.MapSearchForm_map__fWwDh {
  height: 100%; }

@media (max-width: 991px) {
  .MapSearchForm_root__2cTjn {
    height: 100%;
    margin-bottom: 0px; }
    .MapSearchForm_root__2cTjn.MapSearchForm_isMobile__zT2hn .MapSearchForm_searchContainer__22eAX {
      height: 60px; }
    .MapSearchForm_root__2cTjn.MapSearchForm_isMobile__zT2hn .MapSearchForm_search__E0I89 {
      top: 9px;
      left: 10px;
      width: calc(100% - 120px); }
  .MapSearchForm_search__E0I89 {
    position: absolute;
    z-index: 10000;
    top: 9px;
    left: 20px;
    min-width: 50%; } }

@media (max-width: 479px) {
  .MapSearchForm_root__2cTjn.MapSearchForm_isMobile__zT2hn .MapSearchForm_search__E0I89 {
    width: calc(100% - 80px);
    min-width: 150px;
    top: 9px;
    left: 10px; }
  .MapSearchForm_search__E0I89 {
    position: absolute;
    z-index: 10000;
    top: 9px;
    left: 15px;
    min-width: calc(100vw - 90px); } }

.InfoWindowHelper_infoWindow__2iSHb {
  min-width: 220px;
  padding-left: 5px; }

.InfoWindowHelper_infoName__152nE {
  font-family: Montserrat, sans-serif;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase; }

.InfoWindowHelper_infoTitle__2ZcH6 {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin-top: 7px !important;
  font-weight: bold; }

.InfoWindowHelper_infoAddress__1wy3f {
  font-family: Montserrat, sans-serif;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400; }

.InfoWindowHelper_buttonContainer__QbHWH {
  text-align: right; }

.InfoWindowHelper_infoButton__DEYK5 {
  letter-spacing: normal;
  letter-spacing: initial;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 8px 15px;
  text-transform: none;
  text-transform: initial;
  font-size: 12px;
  line-height: 11px; }
  .InfoWindowHelper_infoButton__DEYK5:hover {
    box-shadow: none; }

.InfoWindowHelper_infoEmail__3E5tQ,
.InfoWindowHelper_infoTelephoneNumber__3IyNq,
.InfoWindowHelper_infoUrl__3Wf2X,
.InfoWindowHelper_infoWorkingHours__CWlmr,
.InfoWindowHelper_infoVehicleMarks__28UR7 {
  font-family: Montserrat, sans-serif;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400; }

.MapSlider_root__1iOEU {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  z-index: 20000;
  visibility: visible;
  width: 50vw;
  height: 100vh;
  background-color: #fff;
  border-left: 1px solid #f1f1f1;
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease; }
  .MapSlider_root__1iOEU.MapSlider_closed__1PKBX {
    visibility: hidden;
    -webkit-transform: translateX(50vw);
        -ms-transform: translateX(50vw);
            transform: translateX(50vw); }

@media (max-width: 991px) {
  .MapSlider_root__1iOEU {
    width: 100vw; }
    .MapSlider_root__1iOEU.MapSlider_closed__1PKBX {
      -webkit-transform: translateX(100vw);
          -ms-transform: translateX(100vw);
              transform: translateX(100vw); } }

.MapSlider_item__3rQgh {
  padding: 10px 10px 0 40px;
  border-top: 1px solid #f1f1f1;
  text-align: left;
  cursor: pointer; }
  .MapSlider_item__3rQgh:hover {
    background-color: #d8effa; }

.MapSlider_title__17K0h {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px; }

.MapSlider_address__1MLnq {
  font-size: 14px;
  line-height: 20px; }

.MapSlider_header__fOvYU {
  padding-right: 20px;
  padding-left: 40px; }

.MapSlider_search__1oWux {
  margin-bottom: 15px;
  padding-right: 20px;
  padding-left: 40px; }

.VehicleDamageInspectionPointStepContainer_content__1Si4i {
  padding-top: 10px; }

.VehicleDamageInspectionPointStepContainer_search__eEPWv {
  position: absolute;
  top: 3px; }

.DrawingIntroRight_text__1eSX7 {
  text-align: center; }

.DrawingIntroRight_lottieWrapper__mxd9t {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 92%; }

.DrawingIntroRight_lottieSvg__MLuro {
  position: absolute;
  height: 100%;
  width: 100%; }

.SketchMainNavigation_root__J9l4a {
  width: 80px;
  height: 100%;
  background-color: #c21b17;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .SketchMainNavigation_root__J9l4a .SketchMainNavigation_button__3uEmM {
    width: 80px;
    height: 80px;
    padding: 12px;
    border-bottom: 1px solid #a71c34; }

.SketchMainNavigation_icon__3ZnXZ {
  width: 56px;
  height: 56px; }
  .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_participants__2sepr {
    width: 35px;
    height: 47px; }
  .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_weather__3kdrj, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_roadCrossings__1QqHi, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_text__1wYe1, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_draw__1P_t0, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_eraser__3rDNr {
    width: 50px;
    height: 50px; }
  .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_shapes__2Esp6 {
    width: 55px;
    height: 55px; }

.SketchMainNavigation_next__3N2WM {
  height: 120px; }

@media (max-width: 768px) {
  .SketchMainNavigation_root__J9l4a {
    width: 50px; }
    .SketchMainNavigation_root__J9l4a .SketchMainNavigation_button__3uEmM {
      width: 50px;
      height: 67px; }
  .SketchMainNavigation_icon__3ZnXZ {
    width: 32px;
    height: 32px; }
    .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_participants__2sepr {
      width: 32px;
      height: 40px; }
    .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_weather__3kdrj, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_roadCrossings__1QqHi, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_text__1wYe1, .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_draw__1P_t0 {
      width: 32px;
      height: 32px; }
    .SketchMainNavigation_icon__3ZnXZ.SketchMainNavigation_shapes__2Esp6 {
      width: 34px;
      height: 34px; } }

@media (max-height: 500px) {
  .SketchMainNavigation_root__J9l4a {
    width: 64px; }
    .SketchMainNavigation_root__J9l4a .SketchMainNavigation_button__3uEmM {
      width: 64px;
      height: 64px; }
      .SketchMainNavigation_root__J9l4a .SketchMainNavigation_button__3uEmM > * {
        -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
                transform: scale(0.8); } }

.SketchNavigationButton_container__2_Dh6 {
  width: 80px;
  height: 60px; }

.SketchNavigationButton_container__2_Dh6 .SketchNavigationButton_button__1pe5I {
  width: 80px;
  height: 60px; }

.SketchNavigationButton_container__2_Dh6 .SketchNavigationButton_button__1pe5I .SketchNavigationButton_icon__1uqn8 {
  width: 37px;
  height: 37px; }

.SketchSubNavigation_items__2PEuP .SketchSubNavigation_item__3-6ks {
  width: 80px;
  height: 60px; }

.SketchSubNavigation_items__2PEuP .SketchSubNavigation_item__3-6ks .SketchSubNavigation_icon__BQq-D {
  width: 37px;
  height: 37px; }

.SketchSubNavigation_root__1Mrg- {
  min-height: 100%;
  background-color: #333; }

.SketchSubNavigation_header__18Wsh {
  padding: 14px;
  border-bottom: 1px solid #252525;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse; }
  .SketchSubNavigation_header__18Wsh .SketchSubNavigation_close__2VN9j {
    width: 30px;
    height: 30px;
    cursor: pointer; }

.SketchSubNavigation_items__2PEuP {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.SketchSubNavigation_text__3O-gY {
  margin: 0 10px 10px; }
  .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textSmall__21SGc, .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textMedium__U0u2n, .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textLarge__3wEw7 {
    text-transform: none;
    padding: 9px 9px; }
  .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textSmall__21SGc {
    font-size: 12px; }
  .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textMedium__U0u2n {
    font-size: 16px; }
  .SketchSubNavigation_text__3O-gY .SketchSubNavigation_textLarge__3wEw7 {
    font-size: 20px; }

.SketchSubNavigation_deer__1D-7p {
  -webkit-transform: scale(0.7);
      -ms-transform: scale(0.7);
          transform: scale(0.7); }

.SketchSubNavigation_roadCrossing__ToJbB {
  position: relative;
  top: 3px;
  width: 57px !important;
  height: 57px !important; }

.SketchSubNavigation_shape__2Eq8b {
  -webkit-transform: scale(1.34);
      -ms-transform: scale(1.34);
          transform: scale(1.34); }

.SketchSubNavigation_participant__2ivdX {
  width: 57px !important;
  height: 92px !important;
  -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
          transform: scale(0.6); }

.SketchNavigation_root__1QluO {
  height: 100%;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-transition: -webkit-transform 400ms ease;
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0; }
  .SketchNavigation_root__1QluO.SketchNavigation_hidden__1qvRt {
    -webkit-transform: translate(230px);
        -ms-transform: translate(230px);
            transform: translate(230px); }
  .SketchNavigation_root__1QluO.SketchNavigation_visible__3yHq2 {
    -webkit-transform: translate(0);
        -ms-transform: translate(0);
            transform: translate(0); }
  .SketchNavigation_root__1QluO .SketchNavigation_sub__N3frk {
    width: 240px; }

@media (max-width: 479px) {
  .SketchNavigation_root__1QluO.SketchNavigation_hidden__1qvRt {
    -webkit-transform: translate(70px);
        -ms-transform: translate(70px);
            transform: translate(70px); }
  .SketchNavigation_root__1QluO .SketchNavigation_sub__N3frk {
    width: 80px; } }

.SketchBottomNavigation_root__1Rwpu {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0; }
  .SketchBottomNavigation_root__1Rwpu .SketchBottomNavigation_icon__35Yj7 {
    width: 32px;
    height: 32px; }

.SketchCanvasPreview_root__L1yJl {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .SketchCanvasPreview_root__L1yJl .SketchCanvasPreview_cannotDrop__XzxzI {
    opacity: 0.5; }

.SketchDrawing_root__v4DpU {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair; }

.Sketch_root__1sk_l {
  position: absolute;
  top: 0;
  left: 0;
  right: 90px;
  bottom: 0;
  overflow: hidden;
  background-color: white; }
  @media (max-width: 768px) {
    .Sketch_root__1sk_l {
      right: 60px; } }

.Sketch_back__2S1I6 {
  position: absolute;
  z-index: 10000;
  top: 30px;
  left: 30px;
  display: -webkit-flex;
  display: flex; }
  .Sketch_back__2S1I6 .Sketch_icon__2Jhzr {
    width: 38px;
    height: 38px;
    display: -webkit-flex;
    display: flex; }

.CheckboxShowMoreWrapper_showMoreWrapper__1VZpg {
  height: 80px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative; }
  .CheckboxShowMoreWrapper_showMoreWrapper__1VZpg.CheckboxShowMoreWrapper_shown__39iaE {
    height: auto;
    margin-bottom: 0; }

.CheckboxShowMoreWrapper_overlay__275Xr {
  position: absolute;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-linear-gradient(top, transparent, #fff);
  background-image: linear-gradient(to bottom, transparent, #fff); }

.CheckboxShowMoreWrapper_button__2oQ0o {
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  display: block;
  position: relative;
  top: -35px; }

.SummaryTable_header__1jfh_ {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-bottom: 4px;
  text-transform: uppercase; }

.SummaryTable_headerContent__1lt8E, .SummaryTable_headerText__ylu95 {
  margin-top: 13px; }

.SummaryTable_part__-rQ2u {
  margin-bottom: 10px; }

.SummaryTable_item__3_6Hv {
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 8px 0;
  position: relative;
  min-height: 34px; }
  .SummaryTable_item__3_6Hv .SummaryTable_name__1hGcB {
    font-size: 13px;
    line-height: 18px; }
  .SummaryTable_item__3_6Hv .SummaryTable_value__155tI {
    color: #333;
    font-weight: 600;
    cursor: pointer; }
    .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_content__IvEqP {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      max-width: 310px; }
      @media (max-width: 1250px) {
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_content__IvEqP {
          max-width: 19vw; } }
      @media (max-width: 991px) {
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_content__IvEqP {
          max-width: 32vw; } }
      @media (max-width: 768px) {
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_content__IvEqP {
          max-width: 39vw; } }
    .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_edit__BszEw {
      overflow: visible;
      position: relative;
      opacity: 0.6;
      -webkit-transition: opacity 200ms ease-in-out;
      transition: opacity 200ms ease-in-out; }
      .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_edit__BszEw .SummaryTable_icon__2oboA {
        left: 0;
        width: 22px;
        height: 22px; }
      .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_edit__BszEw .SummaryTable_text__MyFal {
        visibility: hidden;
        position: absolute;
        left: 28px;
        color: #ea5a41;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        padding-left: 5px;
        top: 0; }
    .SummaryTable_item__3_6Hv .SummaryTable_value__155tI:hover .SummaryTable_edit__BszEw {
      opacity: 1; }
      .SummaryTable_item__3_6Hv .SummaryTable_value__155tI:hover .SummaryTable_edit__BszEw .SummaryTable_text__MyFal {
        visibility: visible; }
    .SummaryTable_item__3_6Hv .SummaryTable_value__155tI.SummaryTable_variant1__2or5Q .SummaryTable_content__IvEqP {
      width: 100%;
      max-width: 40vw; }
      @media (max-width: 991px) {
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI.SummaryTable_variant1__2or5Q .SummaryTable_content__IvEqP {
          max-width: 65vw; } }
      @media (max-width: 768px) {
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI.SummaryTable_variant1__2or5Q .SummaryTable_content__IvEqP {
          max-width: 70vw; } }
    @media (max-width: 991px) {
      .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_edit__BszEw {
        opacity: 0.6; }
        .SummaryTable_item__3_6Hv .SummaryTable_value__155tI .SummaryTable_edit__BszEw .SummaryTable_text__MyFal {
          visibility: hidden; } }

.SummaryBankAccounts_header__2z7JH {
  font-weight: 700; }

.SummaryInfo_sketch__14csY {
  width: 50%;
  height: 100%;
  background-color: #fff; }

.SummaryInfo_damage__2VeCW {
  border: 1px solid #fff;
  width: 50%;
  background-color: #facdbc; }

.VehicleIconLabel_text__yfRTZ {
  font-weight: 700; }

.VehicleIconLabel_label__2bw1- {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end; }

.SummaryDamagedGoodsInfo_header__3fF9l {
  font-weight: 700; }

.CheckBoxTree_tree__3aNaj {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 13px;
  font-weight: 500;
  border: 2px solid #facdbc;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  min-height: 40px;
  height: 100%;
  text-align: left;
  padding: 3px 10px !important;
  -webkit-transition: background-color .3s, box-shadow .3 ease, opacity .3s ease;
  transition: background-color .3s, box-shadow .3 ease, opacity .3s ease; }
  .CheckBoxTree_tree__3aNaj.CheckBoxTree_selected__1ozTG {
    background-color: #facdbc; }
  .CheckBoxTree_tree__3aNaj:hover {
    background-color: #facdbc;
    border-color: #facdbc; }

.CheckBoxTree_label__ZiR2i {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 25px; }

.CheckBoxTree_icon__usbrn {
  display: inline-block;
  margin-right: 10px; }

.CheckBoxTree_arrow__1RLgI {
  position: absolute;
  top: 7px;
  right: 0;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s; }
  .CheckBoxTree_arrow__1RLgI > div {
    -webkit-transition: color .3s;
    transition: color .3s; }
  .CheckBoxTree_arrow__1RLgI.CheckBoxTree_selected__1ozTG {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }

.CheckBoxTree_children__1xCSF {
  position: relative; }
  .CheckBoxTree_children__1xCSF.CheckBoxTree_collapsed__3SKcy {
    display: none; }

.CheckBoxTree_list__1K__E {
  position: relative;
  margin: 0 0 0 45px; }
  .CheckBoxTree_list__1K__E .CheckBoxTree_arrow__1RLgI {
    margin-right: 25px; }

.CheckBoxTree_childLabel__3hUSX {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.CheckBoxTree_option__3GNy7 {
  margin: 0 10px 0 45px; }

.DomOseba_root__3_v2S {
  font-family: DOM-oseba, sans-serif;
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  color: #ea5a41;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s; }

.DomOseba_variant1__2LHWR {
  color: #fff;
  font-size: 24px;
  height: 24px;
  line-height: 24px; }

.DomOseba_variant2__1beyZ {
  font-size: 20px;
  height: 20px;
  line-height: 20px; }

@media (min-width: 1474px) {
  .HomeDamageTypeRight_ieLgScreen__ZUkWf {
    padding-bottom: 150px; } }

.CondolencesStepContainer_root__3suT_ {
  background: url(/o/eskode-theme/static/media/condolences.2d25c525.png) center;
  background-size: cover;
  height: 100vh;
  width: 100vw; }

.CondolencesStepContainer_buttonContainer__1QgZK {
  position: absolute;
  top: 5vh;
  right: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .CondolencesStepContainer_buttonContainer__1QgZK > * {
    margin-bottom: 5px; }

.CondolencesStepContainer_condolencesTitle__oeHyC {
  font-size: 30px;
  left: 25px;
  right: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.CondolencesStepContainer_condolences__2Wpd0 {
  font-size: 30px;
  left: 25px;
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.CondolencesStepContainer_generaliLogo__z_vnY {
  width: 100px;
  position: absolute;
  bottom: 5vh;
  right: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

@media (max-width: 991px) {
  .CondolencesStepContainer_condolencesTitle__oeHyC, .CondolencesStepContainer_condolences__2Wpd0 {
    font-size: 25px; } }

@media (max-width: 768px) {
  .CondolencesStepContainer_condolencesTitle__oeHyC, .CondolencesStepContainer_condolences__2Wpd0 {
    font-size: 22px; }
  .CondolencesStepContainer_generaliLogo__z_vnY {
    width: 90px; } }

@media (max-width: 479px) {
  .CondolencesStepContainer_condolencesTitle__oeHyC, .CondolencesStepContainer_condolences__2Wpd0 {
    font-size: 18px; }
  .CondolencesStepContainer_generaliLogo__z_vnY {
    width: 70px; } }

.LifeInsuranceClaimRight_item__3Sf71 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.PersonFontIcon_root__3t3Zq {
  color: #ea5a41;
  font-family: "As nesreca", sans-serif;
  font-size: 33px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 2px; }

h2.IntroStepContainer_introTitle__1BT40 {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif; }

.IntroStepContainer_logo__h9Jk5 {
  height: 80px;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 2; }

.IntroStepContainer_generaliLogo__3EmvC {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: 1; }

@media (max-width: 991px) {
  h2.IntroStepContainer_introTitle__1BT40 {
    font-size: 40px;
    line-height: 50px; }
  .IntroStepContainer_logo__h9Jk5 {
    top: 20px;
    right: 40px;
    height: 60px;
    width: 60px; }
  .IntroStepContainer_wiz__2cOD- .IntroStepContainer_logo__h9Jk5 {
    height: auto;
    width: 150px; }
  .IntroStepContainer_generaliLogo__3EmvC {
    width: 350px; } }

@media (max-width: 768px) {
  h2.IntroStepContainer_introTitle__1BT40 {
    font-size: 30px;
    line-height: 40px; } }

@media (max-width: 479px) {
  h2.IntroStepContainer_introTitle__1BT40 {
    font-size: 20px;
    line-height: 26px; } }

.Login_root__2Fcln {
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh; }

.Login_inner__16ID1 {
  max-width: 550px;
  padding: 32px; }
  @media (max-width: 991px) {
    .Login_inner__16ID1 {
      padding: 20px; } }

h2.Maintenance_maintenanceTitle__D_EkB {
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  margin: 10px 0 20px;
  font-family: Oswald, sans-serif; }

.Maintenance_logo__3VcHz {
  height: 70px;
  position: absolute;
  top: 20px;
  right: 20px; }

.Maintenance_image__3Ccpm {
  max-width: 300px; }

@media (max-width: 991px) {
  h2.Maintenance_maintenanceTitle__D_EkB {
    font-size: 40px;
    line-height: 50px; }
  .Maintenance_logo__3VcHz {
    top: 20px;
    left: 40px;
    height: 70px;
    width: auto; } }

@media (max-width: 768px) {
  h2.Maintenance_maintenanceTitle__D_EkB {
    font-size: 30px;
    line-height: 40px; } }

@media (max-width: 479px) {
  h2.Maintenance_maintenanceTitle__D_EkB {
    font-size: 20px;
    line-height: 26px; }
  .Maintenance_logo__3VcHz {
    top: 20px;
    right: 20px;
    left: auto;
    left: initial;
    height: 60px;
    width: 60px; }
  .Maintenance_image__3Ccpm {
    max-width: 160px; } }

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  min-height: 100%;
  overflow-x: hidden;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch !important; }

body > svg {
  display: none; }

* {
  box-sizing: border-box; }

@font-face {
  font-family: 'As vozila';
  src: url(/o/eskode-theme/static/media/as-vozila.178096b4.woff) format("woff"), url(/o/eskode-theme/static/media/as-vozila.c7ca884d.woff2) format("woff2"), url(/o/eskode-theme/static/media/as-vozila.cf337ff1.ttf) format("truetype"), url(/o/eskode-theme/static/media/as-vozila.348d4cae.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'As nesreca';
  src: url(/o/eskode-theme/static/media/as-nesreca.112687ed.woff) format("woff"), url(/o/eskode-theme/static/media/as-nesreca.e58a8b66.woff2) format("woff2"), url(/o/eskode-theme/static/media/as-nesreca.f6d3ce34.ttf) format("truetype"), url(/o/eskode-theme/static/media/as-nesreca.736b6df6.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'AS-ICONS-3';
  src: url(/o/eskode-theme/static/media/AS-ICONS-3.8a432383.woff) format("woff"), url(/o/eskode-theme/static/media/AS-ICONS-3.0c5ecf50.woff2) format("woff2"), url(/o/eskode-theme/static/media/AS-ICONS-3.5646e54d.ttf) format("truetype"), url(/o/eskode-theme/static/media/AS-ICONS-3.c942741c.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Asdom';
  src: url(/o/eskode-theme/static/media/asdom.967e6d45.woff) format("woff"), url(/o/eskode-theme/static/media/asdom.f5f03f1e.woff2) format("woff2"), url(/o/eskode-theme/static/media/asdom.50621377.ttf) format("truetype"), url(/o/eskode-theme/static/media/asdom.59c70cea.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Icomoon';
  src: url(/o/eskode-theme/static/media/icomoon.59f7038e.woff) format("woff"), url(/o/eskode-theme/static/media/icomoon.f31738f5.woff2) format("woff2"), url(/o/eskode-theme/static/media/icomoon.90f97282.ttf) format("truetype"), url(/o/eskode-theme/static/media/icomoon.e18f289b.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'repairService';
  src: url(/o/eskode-theme/static/media/repair-service.e0757486.woff) format("woff"), url(/o/eskode-theme/static/media/repair-service.94b05dd3.woff2) format("woff2"), url(/o/eskode-theme/static/media/repair-service.e6a0844d.ttf) format("truetype"), url(/o/eskode-theme/static/media/repair-service.8c05bdb5.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'DOM-oseba';
  src: url(/o/eskode-theme/static/media/DOM-oseba.2877c6fe.woff) format("woff"), url(/o/eskode-theme/static/media/DOM-oseba.32e9f806.woff2) format("woff2"), url(/o/eskode-theme/static/media/DOM-oseba.36c5a2e8.ttf) format("truetype"), url(/o/eskode-theme/static/media/DOM-oseba.99c072a9.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Generali-toca-poplava-steklo';
  src: url(/o/eskode-theme/static/media/Generali-toca-poplava-steklo.a7188446.woff) format("woff"), url(/o/eskode-theme/static/media/Generali-toca-poplava-steklo.e72dbe89.ttf) format("truetype"), url(/o/eskode-theme/static/media/Generali-toca-poplava-steklo.2e7a5ee0.eot) format("embedded-opentype");
  font-weight: 400;
  font-style: normal; }

