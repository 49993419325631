.container {
  position: relative;

  .suggestionsContainer {
    position: absolute;
    background-color: white;
    color: black;
    z-index: 100;
    width: 100%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    margin-top: -4px;
    max-height: 140px;
    overflow-y: auto;
    -ms-overflow-y: scroll;
  }

  .suggestionsList {
    margin: 0;
    padding: 0;

    .suggestion {
      list-style: none;
      padding: 5px 20px 5px 15px;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;

      &.suggestionHighlighted {
        background-color: #eee;
      }
    }
  }
}
