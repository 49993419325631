.root {
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {

}

.fill {
  flex-grow: 1;
}

.mainColumnSize {
  flex: 1 1 350px;
}

:global .full-width-mobile > div {
  @media (max-width: 1040px) {
    width: 100% !important;
    flex: initial !important;
  }

  @media (max-width: 991px) {
    width: auto !important;
    flex: 1 !important;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    flex: initial !important;
  }
}
