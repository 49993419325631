.infoWindow {
  min-width: 220px;
  padding-left: 5px;
}

.infoName {
  font-family: Montserrat, sans-serif;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.infoTitle {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin-top: 7px !important;
  font-weight: bold;
}

.infoAddress {
  font-family: Montserrat, sans-serif;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.buttonContainer {
  text-align: right;
}

.infoButton {
  letter-spacing: initial;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 8px 15px;
  text-transform: initial;
  font-size: 12px;
  line-height: 11px;

  &:hover {
    box-shadow: none;
  }
}

.infoEmail,
.infoTelephoneNumber,
.infoUrl,
.infoWorkingHours,
.infoVehicleMarks {
  font-family: Montserrat, sans-serif;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
