@import "../../sass/variables";

.root {
  display: flex;
  align-items: flex-end;
  flex: 1;
  clear: both;
  min-height: 50px;
}

.step {
  position: relative;
  height: 50%;
  flex: 1;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #fff;
    opacity: .4;
  }

  &:last-child {
    height: 100%;

    &:after {
      height: 100%;
      opacity: 1;
    }
  }
}

.active {
  height: 100%;
  background-color: #a71c34;
  border-right: none;
  opacity: 1;

  &:after {
    height: 50%;
  }

  &.wiz {
    background-color: $wiz-secondary;
  }
}

.lastActive {
  &:after {
    height: 100%;
    width: 2px;
    opacity: 1;
  }
}

@media (max-width: 479px) {
  .root {
    min-height: 40px;
  }
}