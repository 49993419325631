.root {
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px;
}

.wrapper {
  position: relative;
  min-height: 60px;
  margin-top: 20px;
  padding: 15px 20px 18px 50px;
  border-radius: 3px;
  background-color: #facdbc;
  color: #333;
  font-size: 12px;
}

.img {
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 10px;

  img {
    width: 29px;
    height: 29px;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #333 !important;
}