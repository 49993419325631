.root {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 32px;
  width: 32px;
  cursor: pointer;
 
  &.variant1 {
    top: 24px;
    z-index: 1001;
  }

  &.variant2 {
    height: 45px;
    width: 45px;
    padding: 9px;
    background-color: #ea5a41;
    transition: background-color .3s;

    &:hover {
      background-color: #c21b17;
    }
  }
}

@media (max-width: 991px) {
  .root {
    &.variant1 {
      top: 15px;
    }
  }
}