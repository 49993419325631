.header {
  // border-bottom: 3px solid #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-bottom: 4px;
  text-transform: uppercase;
}

.headerContent, .headerText {
  margin-top: 13px;
}

.part {
  // border-bottom: 1px solid #333;
  margin-bottom: 10px;
}

.item {
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  padding: 8px 0;
  position: relative;
  min-height: 34px;

  .name {
    font-size: 13px;
    line-height: 18px;
  }

  .value {
    color: #333;
    font-weight: 600;
    cursor: pointer;

    .content {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      max-width: 310px;

      @media (max-width: 1250px) {
        max-width: 19vw;
      }

      @media (max-width: 991px) {
        max-width: 32vw;
      }

      @media (max-width: 768px) {
        max-width: 39vw;
      }
    }

    .edit {
      overflow: visible;
      position: relative;
      
      opacity: 0.6;
      transition: opacity 200ms ease-in-out;

      .icon {
        left: 0;

        width: 22px;
        height: 22px;
      }

      .text {
        visibility: hidden;

        position: absolute;
        left: 28px;

        color: #ea5a41;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;

        padding-left: 5px;
        top: 0;
      }
    }

    &:hover {
      .edit {
        opacity: 1;

        .text {
          visibility: visible;
        }
      }
    }

    &.variant1 {
      .content {
        width: 100%;
        max-width: 40vw;

        @media (max-width: 991px) {
          max-width: 65vw;
        }

        @media (max-width: 768px) {
          max-width: 70vw;
        }
      }
    }

    @media (max-width: 991px) {
      .edit {
        opacity: 0.6;

        .text {
          visibility: hidden;
        }
      }
    }
  }
}
