@import "../../sass/variables";

.button {
  border-radius: 3px;
  background-color: #ea5a41;
  padding: 11px 48px;
  border: 2px solid #ea5a41;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  transition: all 200ms ease;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 768px) {
    &:hover {
      background-color: #c21b17;
      border-color: #c21b17;
      color: #fff;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    }
  }

  &.selected {
    background-color: #facdbc;
    color: #c21b17;

    span,
    p {
      color: #c21b17 !important;
    }
  }

  &.checkmark {
    // background-image: url(images/checkmark.svg);
    // background-position: 95% 50%;
    // background-size: 16px;
    // background-repeat: no-repeat;
    // padding-right: 40px;
  }

  &.disabled {
    opacity: 0.39;
    cursor: default;

    &:hover {
      background-color: #ea5a41;
      border-color: #ea5a41;
      box-shadow: none;
    }
  }
}

.label {
  width: 100%;

  span {
    display: inline-block;
  }
}

.iconLabelDescription {
  display: block !important;
  margin-top: 0;
  padding: 10px 15px;
  height: 100%;

  .icon > div {
    margin-bottom: 10px;
  }

  .label {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .description {
    font-size: 11px;
    text-transform: initial;
  }
}

.inverseButton {
  background-color: #ffffff;
  color: #00a8f6;
}

.variant1 {
  background-color: #ffffff;
  color: #c21b17;

  border-width: 1px;
  padding: 18px 49px;
  margin-top: 40px;
  border-radius: 0;
  font-size: 12px;
  line-height: 27px;

  &.wiz {
    color: $wiz-primary;
    border-color: $wiz-primary;

    &:hover {
      background-color: $wiz-primary-2;
      color: #fff;
      border-color: $wiz-primary-2;
    }
  }

  @media (min-width: 768px) {
    &:hover {
      color: #fff;
    }
  }
}

.variant2 {
  padding: 0 !important;

  .label {
    flex: 1;
  }
}

.variant3 {
  width: 100%;
  height: 80px;
  background-color: #c21b17 !important;

  &.selected {
    box-shadow: 0 0 !important;
    background-color: #ea5a41 !important;
    color: #fff !important;
  }

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 0 !important;
      background-color: #ea5a41 !important;
      color: #fff !important;
    }
  }
}

.variant4 {
  width: 100%;
  height: 80px;
  background-color: transparent;
  border: 0;
  border-right: 1px solid #252525;
  border-bottom: 1px solid #252525;
  background-color: transparent !important;

  &.selected {
    box-shadow: 0 0 !important;
    background-color: #252525 !important;
    border-color: #252525 !important;
  }

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 0 !important;
      background-color: #252525 !important;
      border-color: #252525 !important;
    }
  }
}

.variant5 {
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: #a71c34;
  padding: 19px 0 19px 0 !important;
  text-align: center;

  .icon {
    height: 50px;
    margin: 0 !important;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important;
    }
  }
}

.variant6 {
  width: 50px !important;
  height: 50px !important;
  border: 0;
  background-color: #c21b17;

  .icon {
    padding: 6px;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important;
    }

    &.disabled:hover {
      background-color: #ea5a41;
    }
  }

}

.variant7 {
  width: 60px !important;
  height: 60px !important;
  border: 0;
  background-color: #c21b17;

  .icon {
    padding: 12px 6px 6px;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: #ea5a41;
      box-shadow: 0 0 !important;
    }
  }
}

.variant8 {
  padding-left: 55px !important;
  position: relative;
  background-color: #fff;
  border: 2px solid #facdbc;
  border-radius: 3px;
  color: #333;
  text-align: left;

  .icon {
    position: absolute;
    left: 8px;
    top: 20px;

    img {
      width: 35px;
    }
  }

  .description {
    font-size: 13px !important;
    font-weight: 500;
    letter-spacing: initial;
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: #facdbc;
      border-color: #facdbc;
      color: #333;
    }
  }
}

.variant9 {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #00a8f6;
  border-color: #00a8f6;
  padding: 9px 27px;
  font-size: 12px;
  line-height: 17px;
  margin-top: 20px;
  font-weight: 600;

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);
    }
  }
}

.variant10 {
  font-size: 10px;
  line-height: 11px;
  padding: 8px 15px;
}

.variant11 {
  min-height: 46px !important;
  height: 46px !important;
}

.variant12 {
  background-color: #333;

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 0 23px 0 rgba(255, 255, 255, .3);
    }
  }
}

.variant13 {
  display: inline-block !important;
  min-height: 20px !important;
  height: auto !important;
  background-color: #ea5a41 !important;
  padding: 11px 48px !important;
  color: #fff !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  letter-spacing: 2px !important;
  border: 2px solid #ea5a41 !important;

  @media (min-width: 768px) {
    &:hover {
      background-color: #c21b17 !important;
      border-color: #c21b17 !important;
      color: #fff !important;
    }
  }

  &.selected {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
}

.variant14 {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  color: #333;
  border: 2px solid #facdbc;
  border-radius: 3px;
  margin-top: 0;
  min-height: 60px;
  height: 100%;

  @media (min-width: 768px) {
    &:hover {
      background-color: #facdbc;
      border-color: #facdbc;
      color: #333;
    }
  }

  .label {
    flex: 1;
  }
}

.variant15 {
  border: 0 !important;
  border-radius: 0 !important;
  min-height: 0 !important;
  margin-left: 1px;

  &.selected {
    background-color: #3fbdf8;
    color: #fff;
  }
}

.variant16 {
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 1px;
  padding: 8px 30px 8px 10px !important;
  width: 100%;
  display: flex;

  .icon {
    width: 10px;
    position: relative;

    img {
      position: absolute;
      top: -3px;
      height: 16px;
      width: 16px;
    }
  }
}

.variant17 {
  margin-top: -6px;
  border: 1px solid #facdbc;

  @media (min-width: 768px) {
    &:hover {
      border-color: #facdbc;
    }
  }

  &.onlyIcon {
    background-color: transparent;
    height: 55px;
    width: 55px;
  }
}

.variant18 {
  font-size: 12px;
  font-weight: 500;
  line-height: 11px;
  padding: 8px 15px;
  letter-spacing: 0;
  text-transform: none;
}

.variant19 {
  position: relative;
  border: 2px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);

  .label {
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 5px;
  }

  .description {
    font-size: 12px !important;
  }
}

.variant20 {
  padding: 11px 15px;

  &.wiz {
    background-color: $wiz-primary;
    border-color: $wiz-primary;

    &:hover {
      background-color: $wiz-primary-2;
      border-color: $wiz-primary-2;
    }
  }
}

.variant21 {
  min-height: 46px !important;
}

.variant22 {
  min-height: 46px !important;
  padding-left: 10px !important;
}

.variant23 {
  font-size: 12px;
  line-height: 11px;
  padding: 8px 15px;
  text-transform: none;
  letter-spacing: initial;
  font-weight: 400;

  @media (min-width: 768px) {
    &:hover {
      box-shadow: 0 0;
    }
  }
}

.variant24 {
  display: block !important;
  margin-top: 0;
  padding: 10px 15px;
  height: 100%;

  .icon > div {
    text-align: right !important;
    margin-bottom: 20px;
  }

  .label {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }

  .description {
    font-size: 11px;
    padding-bottom: 15px;
  }
}

.variant26 {
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 0 !important;

  &.selected {
    box-shadow: none !important;
  }
}

.variant27 {
  background-color: #ffffff;
  color: #ea5a41;

  @media (min-width: 768px) {
    &:hover {
      background-color: #ffffff;
      color: #ea5a41;
    }
  }
}

.variant25 {
  position: relative;
  border: 2px solid #fff;
  border-radius: 10px;
  flex-direction: column !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);

  .description {
    text-transform: none;
    padding-right: 10px;
    max-width: 100%;
    font-size: 11px !important;
  }

  &.selected {
    padding-right: 24px !important;
  }
}

.iconLabel {
  padding: 10px 37px 10px 10px;

  .icon {
    margin-right: 15px;
  }
}

.onlyIcon {
  width: 64px;
  height: 64px;
  border-radius: 50% !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    width: initial;
  }
}

.variant24 {
  .label {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 1px;
  }

  .icon > div {
    text-align: left !important;
  }

  .description {
    text-transform: none;
    font-size: 12px;
    word-break: break-all;
  }
}

.variant28 {
  flex-direction: column;
  padding: 10px !important;

  .label {
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    text-transform: initial;
  }

  .icon {
    margin: 0 0 10px;
  }
}

.variant4 {
  border-radius: 0 !important;
}

.onlyLabel {
}

.spacing {
  //padding: 10px 37px 10px 10px;
}

@media (max-width: 768px) {
  .button {
    padding: 9px 20px;
  }

  .onlyIcon {
    padding: 11px 20px;
  }

  .variant1 {
    padding: 9px 27px;
  }

  .iconLabelDescription {
    position: relative;
  }

  .variant7 {
    width: 50px !important;
    height: 50px !important;
    border: 0;

    .icon {
      padding: 8px;
      display: flex;
    }
  }

  .variant5 {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      height: 40px;

      img {
        height: 34px;
      }
    }

    .label {
      display: none;
    }
  }

  .variant26 {
    flex-direction: row;

    .label {
      text-align: left;
    }
  }
}

@media (max-width: 479px) {
  .variant1 {
    font-size: 12px;
  }

  .variant7 {
    width: 40px !important;
    height: 40px !important;
    border: 0;

    .icon {
      padding: 8px;
      display: flex;

      img {
        height: 30px;
        width: 30px;
      }
    }
  }
}