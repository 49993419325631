.root {
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  color: #ea5a41;
  transition: transform 0.3s;
}

.tocaPoplavaSteklo {
  font-family: "Generali-toca-poplava-steklo", sans-serif;
}

.asDom {
  font-family: "Asdom", sans-serif;
}

.domOseba {
  font-family: "DOM-oseba", sans-serif;
}

.repairService {
  font-family: "repairService", sans-serif;
}

.icoMoon {
  font-family: "Icomoon", sans-serif;
}

.variant1 {
  font-size: 24px;
  height: 24px;
  line-height: 24px;
}

.variant2 {
  font-size: 20px;
  height: 20px;
  line-height: 20px;
}

.variant3 {
  font-size: 15px;
  height: 10px;
  line-height: 12px;
}
