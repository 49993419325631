.gCD {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #c5281c;
  color: #fff;
  overflow-y: auto;
  text-align: center;
  padding: 15px;
  z-index: 9999999;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);

  a {
    &,
    &:visited,
    &:link,
    &:hover {
      color: #fff;
    }
  }
}

.gCDWiz {
  background-color: #fff;
  color: #000;

  .gCDHeading {
    color: #000;
  }
  .buttonLink {
    color: #000;
  }

  a {
    color: #27bdbe !important;
  }
}

.gCDContainer {
  padding-bottom: 0 0 5px 0;
}

.right {
  > * {
    margin: 5px;
  }
}

.gCDHeading {
  text-align: center;
  margin-bottom: 20px;
  font-family: Oswald, sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 1.4;
  text-transform: uppercase;
}

.gCDText {
  margin-bottom: 15px;
  max-height: 200px;
  overflow-y: auto;
  font-size: 1rem; /* 16/16 */
  a {
    &,
    &:visited,
    &:link,
    &:hover {
      color: #fff;
    }
  }
}

.gCDButtons {
  text-align: center;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
}

.gCDButton {
  display: block;
  background-color: #fff;
  color: #000;
  padding: 15px 20px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

/* cookie settings */
.gCS {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  background-color: #c5281c;
  color: #fff;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  max-width: 750px;
  z-index: 9999999;
  overflow-y: auto;
  margin: 0 auto;

  a {
    &,
    & :visited,
    &:link,
    &:hover {
      color: #fff;
    }
  }
}

.gCSWiz {
  background-color: #fff;
  color: #000;

  .gCSHeading {
    color: #000;
  }

  .buttonLink {
    color: #000;
  }
  p {
    color: #000;
  }

  a {
    color: #27bdbe !important;
  }

  .gCSSettings span {
    color: #000;
  }
}

.gCSContainer {
  padding-bottom: 30px;
}

.gCSHeading {
  font-family: Oswald, sans-serif;
  font-size: 1.75rem; /* 28/16 */
  line-height: 1.6;
  text-align: center;
  color: #fff;
}

.gCSSettings {
  margin-bottom: 25px;
}

.gCSContainer,
.gCDContainer {
  margin: 0 auto;
}

.buttonLink {
  font-size: 1rem; /* 16/16 */
  font-weight: 400;
  color: #fff;
  text-transform: none;
  background-color: transparent;
  border: none;
  margin-top: 10px;

  span {
    text-decoration: underline;
    letter-spacing: 0;
  }

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
}

.gCSCheckbox {
  span {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .gCD {
    padding-top: 35px;
    padding-bottom: 30px;
  }

  .gCDContainer {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
  }

  .left {
    flex: 8 0 450px;
    margin-right: 15px;
  }

  .right {
    flex: 0 0 200px;
  }

  .gCDHeading {
    margin-bottom: 10px;
    margin-top: 0;
    text-align: left;
  }

  .gCDText {
    max-height: none;
    overflow: visible;
    text-align: left;
    margin-bottom: 0;
  }

  .gCDButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: center;
    flex: 1 0 100px;
    max-width: 300px;
  }

  .gCDButton {
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
  }

  /* cookie settings */
  .gCS {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    width: 100%;
    max-width: 850px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.4);
    height: auto;
    max-height: 100vh;
  }

  .gCSContainer {
    max-width: 625px;
    padding-bottom: 0;
  }

  .gCSHeading {
    font-size: 3.2rem; // 35px
  }

  .gCSSettings {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 992px) {
  .gCSContainer,
  .gCDContainer {
    max-width: 1400px;
  }
}
