.sketch {
  width: 50%;
  height: 100%;
  background-color: #fff;
}

.damage {
  border: 1px solid #fff;
  width: 50%;
  background-color: #facdbc;
}
