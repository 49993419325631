.root {
  color: #ea5a41;
  font-size: 35px;
  line-height: 35px;
  font-weight: 400;

  &.vehicle {
    // color: #fff;
    font-family: "As vozila", sans-serif;
  }

  &.company {
    font-family: "AS-ICONS-3", sans-serif;
  }
}
