.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 30px 0;
}

:global .react-datepicker {
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  padding: 0 15px;
  border: 0;

  @media (min-width:992px) and (max-width: 1230px) {
    width: 100%;
    max-width: 400px;
  }

  .react-datepicker__header {
    background-color: #fff;
    border: 0;
  }

  .react-datepicker__day--disabled {
    &:hover {
      background-color: transparent !important;
      border-color: #facdbc !important;
    }
  }

  .react-datepicker__day--disabled.react-datepicker__day--in-range.react-datepicker__day--outside-month {
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }

  .react-datepicker__navigation {
    &--previous {
      background-image: url(../../images/left-arrow-grey.svg);
      left: 25px;
    }

    &--next {
      background-image: url(../../images/right-arrow-grey.svg);
      right: 25px;
    }

    border: 0;
    height: 29px;
    width: 29px;
    background-size: 29px 29px;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  }

  .react-datepicker__current-month {
    display: block;
    margin-bottom: 10px;
    padding-right: 51px;
    padding-left: 51px;
    font-size: 16px;
    line-height: 35px;
    text-align: center;

    color: #333;
    font-weight: 400;
  }

  .react-datepicker__header__dropdown {
    .react-datepicker__year-dropdown--scrollable {
      background-color: #666;
      border-color: #666;
      color: #333;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
  
      &::-webkit-scrollbar { 
        width: 0;
        height: 0;
      }
    }

    .react-datepicker__year-read-view {
      padding-right: 17px;
      position: relative;

      &--down-arrow {
        position: absolute;
        right: 0;
      }
    }

    .react-datepicker__year-option:hover {
      background-color: #888 !important;
    }

    .react-datepicker__year-read-view--selected-year {
      font-size: 14px;
      line-height: 23px;
      color: #333;
    }

    .react-datepicker__year-dropdown-container {
      position: relative;
    
      select {
        padding: 5px 25px 5px 20px;
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        color: #333;
        border: 0;
        outline: 0px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
  
        &::-ms-expand {
          display: none;
        }

        &:focus {
          outline: 0;
        }

        option {
          background-color: #fff;
          color: #5f5f5f;
          font-size: 13px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        right: 13px;
        top: 50%;
        margin-top: -3px;
        height: 0;
        width: 0;
        border-top: 6px solid #5f5f5f;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        pointer-events: none;
      }
    }
  }

  .react-datepicker__month-container {
    @media (min-width:992px) and (max-width: 1230px) {
      width: 100%;
    }
  }

  .react-datepicker__week {
    @media (min-width:992px) and (max-width: 1230px) {
      display: flex;
    }
  }

  .react-datepicker__day-name {
    color: #333;
    font-size: 12px;
    width: 38px;
    line-height: 29px;
    margin-left: 5px;
    margin-right: 10px;

    @media (max-width: 425px) {
      width: 31px;
      line-height: 31px;
      font-size: 15px;
      margin-right: 5px;
    }

    @media (min-width:992px) and (max-width: 1230px) {
      flex: 1 1 14%;
      width: auto;
    }
  }

  .react-datepicker__day {
    font-size: 17px;
    color: #333;
    width: 38px;
    line-height: 36px;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 10px;

    border-radius: 50%;
    border: 1px solid #facdbc;
    background-color: hsla(0, 0%, 100%, .11);
    transition: all .3s;

    @media (max-width: 425px) {
      width: 31px;
      line-height: 31px;
      font-size: 15px;
      margin-right: 5px;
    }

    @media (min-width:992px) and (max-width: 1230px) {
      flex: 1 1 14%;
      width: auto;
      line-height: 120%;
      padding-top: 1.5%;
      padding-bottom: 1.5%;
    }

    &:hover {
      border-radius: 50%;
      background-color: #facdbc;
    }

    &--selected {
      color: #fff;
      background-color: #c21b17 !important;
      border-color: #c21b17;
    }

    &--outside-month {
      opacity: 0.42;

      &:hover {
        background-color: #fff !important;
      }
    }

    &--in-range {
      color: #fff;
      background-color: #c21b17 !important;
      border-color: #c21b17 !important;

      &:hover {
        background-color: #c21b17 !important;
        border-color: #c21b17 !important;
      }
    }

    &--in-selecting-range {
      background-color: rgba(255, 255, 255, .3);
      border-radius: 50%;

      &:not(.react-datepicker__day--in-selecting-range) {
        color: #00a8f6;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 400px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin lightStyle {
  :global .react-datepicker {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.14);

    .react-datepicker__triangle {
      border-bottom-color: #fff !important;
      border-top-color: #fff !important;

      &:before {
        display: none;
        top: 0 !important;
        border-bottom-color: #fff !important;
      }
    }

    .react-datepicker__day {
      color: #333;
      border: 1px solid #e7e7e7;

      &:hover {
        background-color: #facdbc;
      }
    }

    .react-datepicker__current-month {
      color: #333;
    }

    .react-datepicker__year-read-view--selected-year {
      color: #333;
    }

    .react-datepicker__year-dropdown--scrollable {
      background-color: #fff;
      border-color: #e7e7e7;
      color: #333;
    }

    .react-datepicker__year-option:hover {
      background-color: #f1f1f1 !important;
    }

    .react-datepicker__header {
      background-color: #fff;
    }

    .react-datepicker__day-name {
      color: #c4c4c4;
    }

    .react-datepicker__day--selected {
      color: #fff;
      background-color: #c21b17 !important;
      border-color: #c21b17;
    }

    .react-datepicker__navigation {
      &--previous {
        background-image: url(../../images/left-arrow-grey.svg);
      }

      &--next {
        background-image: url(../../images/right-arrow-grey.svg);
      }
    }
  }
}

:global .react-datepicker-popper {
  @include lightStyle
}

.modal {
  @include lightStyle;
}
