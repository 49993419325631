.root {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  z-index: 20000;
  visibility: visible;

  width: 50vw;
  height: 100vh;

  background-color: #fff;
  border-left: 1px solid #f1f1f1;

  transition: transform 200ms ease;

  &.closed {
    visibility: hidden;
    transform: translateX(50vw);
  }
}

@media (max-width: 991px) {
  .root {
    width: 100vw;

    &.closed {
      transform: translateX(100vw);
    }
  }
}

.item {
  padding: 10px 10px 0 40px;
  border-top: 1px solid #f1f1f1;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: #d8effa;
  }
}

.title {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.address {
  font-size: 14px;
  line-height: 20px;
}

.header {
  padding-right: 20px;
  padding-left: 40px;
}

.search {
  margin-bottom: 15px;
  padding-right: 20px;
  padding-left: 40px;
}