.root {
  color: #333;
  font-size: 11px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &.variant2 {
    color: #8c8c8c;
  }
}
