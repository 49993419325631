.root {
  height: 100%;
  z-index: 10;
  display: flex;
  transition: transform 400ms ease;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  &.hidden {
    transform: translate(230px);
  }

  &.visible {
    transform: translate(0);
  }

  .sub {
    width: 240px;
  }
}

@media (max-width: 479px) {
  .root {
    &.hidden {
      transform: translate(70px);
    }

    .sub {
      width: 80px;
    }
  }
}
