.root {
  position: absolute;
  pointer-events: none;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .cannotDrop {
    opacity: 0.5;
  }
}
