.root {
  position: relative;
  width: 100%;
  padding-right: 35px;
  display: inline-block;
  margin-bottom: 10px; 

  .text {
    width: 100%;
  }

  .delete {
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
    width: 24px;
  }

  span {
    max-width: 50px;
  }
}

@media (max-width: 479px) {
  .root {
  }
}