.root {
  position: relative;
  width: 100%;
  max-width: calc(25% - 20px);
  display: inline-block;
  margin: 0 10px 15px;

  .image {
    width: 100%;
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    background: #ea5a41;
    cursor: pointer;
    width: 24px;
  }
}

@media (max-width: 479px) {
  .root {
    max-width: calc(50% - 20px);
  }
}