.root {
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  outline: none;
  background-color: #ea5a41;
  padding: 4px 10px;
  border-radius: 2px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition: all 200ms ease;

  &:hover {
    background-color: #c21b17 !important;
    border-color: #c21b17 !important;
    color: #fff !important;
  }

  &.variant2 {
    color: #333;
    text-align: left;
    text-decoration: underline;
    overflow-wrap: break-word;
    -ms-word-break: break-all;
    max-width: calc(100% - 90px);
  }

  &.variant1 {
    font-size: 14px;
    background-color: #fff;
    color: #ea5a41;
  }

  &.variant2 {
    background-color: #fff;
    color: #ea5a41;
    font-size: 11px;

    &:hover {
      background-color: #fff !important;
      border-color: #fff !important;
      color: #ea5a41 !important;
    }
  }

  &.variant3 {
    background-color: transparent;
    color: #333;
    font-size: 11px;
    font-weight: 600;

    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #333 !important;
    }
  }

  &.variant4 {
    background-color: transparent;
    color: #ea5a41;
    font-size: 14px;
    padding-left: 0;

    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #ea5a41 !important;
    }
  }

  &.variant5 {
    display: flex;
    text-align: left;
    max-width: calc(100% - 28px);
    width: 100%;
    background-color: transparent;
    color: #333;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 10px 4px 2px;

    p {
      word-break: break-all;
      max-width: calc(100% - 43px);
    }

    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
      color: #333 !important;
    }
  }
}
