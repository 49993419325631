.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 90px;
  bottom: 0;
  overflow: hidden;
  background-color: white;

  @media (max-width: 768px) {
    right: 60px;
  }
}

.back {
  position: absolute;
  z-index: 10000;
  top: 30px;
  left: 30px;
  display: flex;

  .icon {
    width: 38px;
    height: 38px;
    display: flex;
  }
}
