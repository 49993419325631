.choice {
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  color: #333;
  border: 2px solid #facdbc;
  border-radius: 3px;
  margin-top: 0;
  min-height: 60px;
  height: 100%;
  padding: 10px 37px 10px 10px;

  &:hover {
    background-color: #facdbc;
    border-color: #facdbc;
    color: #333;
  }
}

.variant3 {
  border: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.variant4 {
  border: 0;
  border-radius: 0 !important;
  border-right: 1px solid #252525;
  border-bottom: 1px solid #252525;
  box-shadow: none !important;
}

.variant19 {
  display: block;
}

@media (max-width: 479px) {
  .choice {
    min-height: 51px;
    font-size: 11px;
  }
}
