.root {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #ea5a41;
  color: #fff;
  font-weight: 500;
  text-align: center;

  &.light {
    background-color: #fff;
    color: #00a8f6;
  }

  &.dark {
    background-color: #00a8f6;
    color: #fff;
  }
}