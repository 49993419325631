.root {
  color: #333;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 19px;
  position: relative;

  & > div {
    position: absolute;
    left: -1px;
  }
}
