.root {
  background: url("../../../../src/images/condolences/condolences.png") center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.buttonContainer {
  position: absolute;
  top: 5vh;
  right: 25px;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 5px;
  }
}

.condolencesTitle {
  font-size: 30px;
  left: 25px;
  right: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.condolences {
  font-size: 30px;
  left: 25px;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.generaliLogo {
  width: 100px;
  position: absolute;
  bottom: 5vh;
  right: 25px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 991px) {
  .condolencesTitle, .condolences {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .condolencesTitle, .condolences {
    font-size: 22px;
  }

  .generaliLogo {
    width: 90px;
  }
}

@media (max-width: 479px) {
  .condolencesTitle, .condolences {
    font-size: 18px;
  }

  .generaliLogo {
    width: 70px;
  }
}
