.root {
  position: relative;
  margin-bottom: 30px;
  height: 100%;
  min-height: 500px;
}

.root.isMobile {
  height: 100vh;
  margin-bottom: 0px;

  .search {
    width: calc(100% - 120px);
    top: 9px;
    left: 10px;
  }
}

.search {
  position: absolute;
  z-index: 10000;
  top: 9px;
  left: 40px;
  min-width: 50%;
}

.map {
  height: 100%;
}

@media (max-width: 991px) {
  .root {
    height: 100%;
    margin-bottom: 0px;

    &.isMobile {
      .searchContainer {
        height: 60px;
      }

      .search {
        top: 9px;
        left: 10px;
        width: calc(100% - 120px);
      }
    }
  }

  .search {
    position: absolute;
    z-index: 10000;
    top: 9px;
    left: 20px;
    min-width: 50%;
  }
}

@media (max-width: 479px) {
  .root.isMobile {
    .search {
      width: calc(100% - 80px);
      min-width: 150px;
      top: 9px;
      left: 10px;
    }
  }
  
  .search {
    position: absolute;
    z-index: 10000;
    top: 9px;
    left: 15px;
    min-width: calc(100vw - 90px);
  }
}