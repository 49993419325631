@import 'components/sketch/navigation/SketchCommonButton.scss';

.container {
  @extend %container;

  .button {
    @extend %button;

    .icon {
      @extend %icon;
    }
  }
}
