.text {
  font-weight: 700;
}

.label {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
