.root {
  width: 80px;
  height: 100%;
  background-color: #c21b17;
  display: flex;
  flex-direction: column;

  .button {
    width: 80px;
    height: 80px;
    padding: 12px;
    border-bottom: 1px solid #a71c34;
  }
}

.icon {
  width: 56px;
  height: 56px;

  &.participants {
    width: 35px;
    height: 47px;
  }

  &.weather, &.roadCrossings, &.text, &.draw, &.eraser{
    width: 50px;
    height: 50px;
  }

  &.shapes {
    width: 55px;
    height: 55px;
  }
}

.next {
  height: 120px;
}

@media (max-width: 768px) {
  .root {
    width: 50px;

    .button {
      width: 50px;
      height: 67px;
    }
  }

  .icon {
    width: 32px;
    height: 32px;

    &.participants {
      width: 32px;
      height: 40px;
    }

    &.weather, &.roadCrossings, &.text, &.draw {
      width: 32px;
      height: 32px;
    }

    &.shapes {
      width: 34px;
      height: 34px;
    }
  }
}

@media (max-height: 500px) {
  .root {
    width: 64px;

    .button {
      width: 64px;
      height: 64px;

      & > * {
        transform: scale(0.8);
      }
    }
  }
}
