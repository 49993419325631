.root {
  position: relative;
}

.enter {
  opacity: 0;

  &.enterActive {
    opacity: .99;
    transition: opacity 200ms ease-in-out;
  }
}

.exit {
  opacity: .99;

  &.exitActive {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
}
