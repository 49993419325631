.root {
  font-family: Asdom, sans-serif;
  font-size: 35px;
  height: 39px;
  line-height: 40px;
  color: #ea5a41;
  transition: transform .3s;
}

.variant1 {
  font-size: 24px;
  height: 24px;
  line-height: 24px;
}

.variant2 {
  font-size: 20px;
  height: 20px;
  line-height: 20px;
}
