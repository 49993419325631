.text {
  text-align: center;
}

.lottieWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 92%;
}

.lottieSvg {
  position: absolute;
  height: 100%;
  width: 100%;
}